import React, { useRef } from "react";

const RenderChart = ({
  title,
  url,
  width,
}: {
  title: string;
  url: string;
  width: string;
}) => {
  const Iref = useRef<HTMLIFrameElement>(null);
  return (
    <div
      style={{
        padding: "0.5rem 0",
        width: width,
        height: "25rem",
      }}
    >
      <div
        style={{
          height: "100%",
          flexGrow: 1,
          padding: "1rem",
          borderRadius: "0.4rem",
          boxShadow: "0px 0px 10px 3px rgba(230,230,230,0.69)",
          boxSizing: "border-box",
          margin: "0 0.5rem",
        }}
      >
        <div
          style={{
            fontSize: "1rem",
            fontWeight: "600",
            padding: "0rem 0.3rem 1rem 0.3rem",
          }}
        >
          {title}
        </div>
        <iframe
          style={{
            width: "100%",
            height: "80%",
            border: 0,
          }}
          ref={Iref}
          title="Graph"
          sandbox="allow-scripts allow-same-origin"
          src={url}
        />
      </div>
    </div>
  );
};

export default RenderChart;
