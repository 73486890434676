import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0 0 20px",
    height: "100%",
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "0 20px",
    justifyContent: "center",
    background: "#FFFFFF",
    flexGrow: 1,
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
  },
  title: {
    fontWeight: 500,
    fontSize: "1.1rem",
    padding: "10px 0",
  },
  tableContainer: {
    overflow: "scroll",
    flexGrow: 1,
    position: "relative",
  },
  header: {
    padding: "10px",
    fontWeight: 600,
    fontSize: "0.75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  incidentCont: {
    padding: "10px 10px 0 10px",
    display: "flex",
    flexDirection: "column",
    fontSize: "0.7rem",
    "& > div": {
      maxWidth: 300,
    },
  },
  alarmCont: {
    display: "flex",
  },
  alarm: {
    fontWeight: 600,
    // minWidth: 80,
  },
  name: {
    // padding: "0 5px !important",
    // maxWidth: "70%",
    // "& a": {
    //   whiteSpace: "nowrap",
    //   overflow: "hidden",
    //   textOverflow: "ellipsis",
    // },
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // "&:hover": {
    //   overflow: "visible",
    // },
  },
  message: {
    padding: "5px 0",
    fontWeight: 300,
    flexDirection: "column",
    overflow: "hidden",
    marginBottom: 5,
    transition: "0.3s all",
    textAlign: "justify",
    textJustify: "inter-word",
  },
  detail: {
    fontWeight: 300,
    padding: "0 2.5px",
  },
  expand: {
    fontWeight: 300,
    width: "30%",
    color: "#096DD9",
    padding: "5px 0",
    cursor: "pointer",
  },
  rowItem: {
    fontSize: "0.75rem",
    fontWeight: 300,
    height: "8.2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  status: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    borderRadius: 20,
    fontSize: "0.75rem",
    width: "70px",
    margin: "10px auto",
  },
});

export default styles;
