import { Spin, Tooltip } from "antd";
import React from "react";

export interface User {
  name: string;
  email: string;
  picURL: string;
}

const ViewList = ({
  width,
  views,
  metadataLoading,
}: {
  width: number;
  views: User[];
  metadataLoading: boolean;
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: `${width * 0.55}px`,
        position: "relative",
      }}
    >
      {!metadataLoading ? (
        <div
          style={{
            display: "flex",
            width: `${width}px`,
            height: `${width / 6}px`,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {views.length
            ? views.map((view, index) => {
                if (index > 4) return null;

                if (index === 4) {
                  const count = views?.length - 4;
                  const remaining = () => {
                    return (
                      <div
                        style={{
                          height: "100px",
                          overflowY: "scroll",
                          color: "black",
                          fontSize: "0.7rem",
                          fontWeight: 300,
                        }}
                      >
                        {views?.map((v, i) => {
                          if (i >= 4) return <div>{v.name}</div>;
                          else return null;
                        })}
                      </div>
                    );
                  };
                  return (
                    <Tooltip
                      key={index}
                      placement="bottom"
                      color="white"
                      title={remaining}
                    >
                      <div
                        style={{
                          width: `${width / 6}px`,
                          height: `${width / 6}px`,
                          border: "solid 2px white",
                          fontSize: `${width / 18}px`,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#D9D9D9",
                          borderRadius: "50%",
                          position: "relative",
                          transform: `translateX(-${15 * index}px)`,
                        }}
                      >
                        <div>{count % 10 === count ? count : "9+"}</div>
                      </div>
                    </Tooltip>
                  );
                }
                return (
                  <Tooltip
                    placement="top"
                    color="white"
                    title={
                      <span
                        style={{
                          color: "black",
                          fontSize: "0.7rem",
                          fontWeight: 300,
                        }}
                      >
                        {view.name}
                      </span>
                    }
                    key={index}
                  >
                    <div
                      style={{
                        width: `${width / 6}px`,
                        height: `${width / 6}px`,
                        background: "whit1e",
                        borderRadius: "50%",
                        transform: `translateX(-${15 * index}px)`,
                      }}
                    >
                      <img
                        src={view.picURL}
                        alt=""
                        style={{
                          width: `${width / 6}px`,
                          height: `${width / 6}px`,
                          borderRadius: "50%",
                          border: "solid 2px white",
                        }}
                      />
                    </div>
                  </Tooltip>
                );
              })
            : "--"}
        </div>
      ) : (
        <Spin style={{ margin: "0 auto" }} size="small" />
      )}
    </div>
  );
};

export default ViewList;
