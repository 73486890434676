import MDEditor from "@uiw/react-md-editor";
import React from "react";
import { Note } from ".";
import { createUseStyles } from "react-jss";

const style = createUseStyles({
  container: {
    height: "100%",
    display: "flex",
    paddingBottom: "1rem",
    justifyContent: "space-between",
  },
  editorCont: {
    // margin: "1rem 2rem",
    width: "49%",
    display: "flex",
    flexDirection: "column",
    // flexGrow: 1,
  },
  title: {
    padding: "5px 10px",
    margin: "10px 0",
    fontSize: "0.8rem",
    fontWeight: 500,
    alignSelf: "flex-start",
    display: "flex",
    width: 150,
    justifyContent: "flex-start",
    background: "rgba(24, 144, 255, 0.1)",
    borderRadius: "5px",
    borderLeft: "6px solid #1890FF",
  },
  editor: {
    flexGrow: 1,
    overflowY: "scroll",
    border: "1px solid grey",
    borderRadius: 5,
  },
});

const RenderNote = ({
  note,
  setView,
}: {
  note?: Note | null;
  setView: any;
}) => {
  const classes = style();

  return (
    <div className={classes.container}>
      <div className={classes.editorCont}>
        <div className={classes.title}>CAUSE</div>
        <div className={classes.editor} data-color-mode="light">
          <MDEditor.Markdown
            style={{ padding: 25, position: "relative" }}
            source={note?.cause}
            linkTarget="_blank"
          />
        </div>
      </div>
      <div className={classes.editorCont}>
        <div className={classes.title}>RESOLUTION</div>
        <div className={classes.editor} data-color-mode="light">
          <MDEditor.Markdown
            style={{ padding: 25, position: "relative" }}
            source={note?.resolution}
            linkTarget="_blank"
          />
        </div>
      </div>
    </div>
  );
};

export default RenderNote;
