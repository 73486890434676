import React from "react";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";
import Profile from "./profile";
import useStyle from "./styles";
import { navlist } from "../utils";
import ViewList from "../ViewList";
import withStyle from "react-jss";
export interface NavItem {
  path: string;
  tag: string;
}

interface NavItemProp {
  item: NavItem;
}

interface IProps {
  classes: {
    [T in keyof ReturnType<typeof useStyle>]: string;
  };
}

const Navbar: React.FC<IProps> = (props) => {
  const { classes } = props;
  const Nav: React.FC<NavItemProp> = ({ item }) => {
    return (
      <NavLink to={item.path}>
        {({ isActive }: { isActive: boolean }) => (
          <div
            className={classes.tab + " " + (isActive ? classes.selected : null)}
          >
            {item.tag}
          </div>
        )}
      </NavLink>
    );
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <span className={classes.brand}>Tarzan</span>
        <div className={classes.tabs}>
          <div className={classes.left}>
            {navlist.map((item: NavItem, index: number) => (
              <Nav item={item} key={index} />
            ))}
          </div>
          <div className={classes.right}>
            <ViewList metadataLoading={false} views={[]} width={200} />
            <Profile />
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default withStyle(useStyle)(Navbar);
