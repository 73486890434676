import { Layout, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";
import { getSourceData } from "./service";
import styles from "./styles";

export interface SubItem {
  isActive?: boolean;
  label: string;
  children?: SubItem[];
  path?: string;
  key: string | number;
}

interface SidebarProps {
  items: ({
    incidentId,
    eventId,
    source,
  }: {
    incidentId?: string;
    eventId?: string;
    source?: Source[];
  }) => SubItem[];
  indent?: number;
  step?: number;
}

interface SideMenuProps extends SidebarProps {
  loading: boolean;
  incidentId?: string;
  eventId?: string;
  source?: Source[];
}

export type Source =
  | "RDS"
  | "NEWRELIC_APDEX"
  | "EVENT_DATA"
  | "POSTGRES"
  | "ECS"
  | "REDIS"
  | "NEWRELIC_SYNTHETICS";

function Sidebar(props: SidebarProps) {
  const [eventId, setEventId] = useState<string>();
  const [incidentId, setIncidentId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [sourceData, setSourceData] = useState<Source[]>([]);
  const params = useParams();

  useEffect(() => {
    if (params.incident_id && params.incident_id !== incidentId) {
      setIncidentId(params.incident_id);
    }
    if (params.event_id && params.event_id !== eventId) {
      setEventId(params.event_id);
    }
  }, [params, eventId, incidentId, setEventId, setIncidentId]);

  useEffect(() => {
    const fetchSource = async () => {
      try {
        setLoading(true);
        const { newSourceData } = await getSourceData(incidentId, eventId);

        setSourceData(newSourceData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    incidentId && fetchSource();
  }, [eventId, incidentId]);
  return (
    <Layout
      style={{
        flexDirection: "row",
      }}
    >
      <>
        <SideMenu
          {...props}
          loading={loading}
          incidentId={incidentId}
          eventId={eventId}
          source={sourceData}
        />
        <Content>
          <Outlet />
        </Content>
      </>
    </Layout>
  );
}

const SideMenu = (props: SideMenuProps) => {
  const classes = styles({ indent: 20 });
  const { items, indent, step, incidentId, eventId, source, loading } = props;
  return (
    <div className={classes.container}>
      {!loading ? (
        <RenderItem
          data={items({ incidentId, eventId, source })}
          indent={indent || 20}
          step={step || 20}
        />
      ) : (
        <Spin />
      )}
    </div>
  );
};

const RenderHeadItem = (props: {
  indent: number;
  label: string;
  unique: string | number;
}) => {
  const classes = styles(props);
  return (
    <div key={props.unique} className={classes.head}>
      {props.label}
    </div>
  );
};

const RenderLinkItem = (props: {
  indent: number;
  isActive: boolean;
  label: string;
  unique: string | number;
}) => {
  const classes = styles(props);
  return (
    <div key={props.unique} className={classes.link}>
      {props.label}
    </div>
  );
};

const RenderItem = ({
  data,
  indent = 20,
  step = 20,
}: {
  data: SubItem[];
  indent?: number;
  step?: number;
}) => {
  return (
    <>
      {data?.map((item, index) => {
        if (item.children) {
          return (
            <div key={index}>
              <RenderHeadItem
                indent={indent}
                label={item.label}
                unique={item.key || index}
              />
              <RenderItem
                data={item.children}
                indent={indent + step}
                step={step}
              />
            </div>
          );
        } else {
          return (
            <NavLink to={item.path} key={index}>
              {({ isActive }: { isActive: boolean }) => (
                <RenderLinkItem
                  indent={indent}
                  isActive={isActive}
                  label={item.label}
                  unique={item.key ? item.key : index}
                />
              )}
            </NavLink>
          );
        }
      })}
    </>
  );
};

export default Sidebar;
