import React from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import { ChartData } from ".";
import { lowerCaseToUpperCase } from "./utils";
import styles from "./styles";
import { getDateTimeUNIX } from "../../../common/utils";

ChartJS.register(...registerables);

const RenderChart = ({ chartdata }: { chartdata: ChartData }) => {
  const classes = styles();

  if (chartdata.unit === "Bytes") {
    chartdata.values = chartdata.values.map((val) => {
      return val / (1024 * 1024);
    });
    chartdata.unit = "MegaBytes";
  }

  const data = {
    labels: chartdata.timestamps,
    datasets: [
      {
        label: chartdata.unit,
        data: chartdata.values,
        fill: false,
        borderColor: "#00bbff",
        tension: 0.1,
      },
    ],
  };

  const findPixel = (time: number, labels: string[], width: number, x: any) => {
    const ratio =
      (time - parseInt(labels[0])) /
      (parseInt(labels[labels.length - 1]) - parseInt(labels[0]));
    return x.getPixelForValue(0) + width * ratio;
  };

  return (
    <div className={classes.chartCont}>
      <div className={classes.chartHeader}>
        <div style={{ fontWeight: "600", padding: "0 0.3rem" }}>
          {lowerCaseToUpperCase.get(`${chartdata.title}`)}
        </div>
        <div style={{ fontWeight: "600", color: "#8f8f8f" }}>
          {" "}
          {`(${chartdata.unit})`}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <Chart
          type="line"
          data={data}
          plugins={[
            {
              id: "AnnotationLine",
              beforeDraw(chart, args, options) {
                const {
                  ctx,
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  chartArea: { top, right, width, height },
                  scales: { x },
                } = chart;
                ctx.save();
                const labels = x.getLabels();
                // const startTime = chartdata.startTime || 0;
                const startTime = 1658136200;
                const endTime = 1658136500;

                const startPixel = findPixel(startTime, labels, width, x);
                const endPixel = findPixel(endTime, labels, width, x);
                // const endPixel = x.getPixelForValue(0) + width * startRatio;
                ctx.strokeStyle = "red";
                ctx.fillStyle = "rgba(245, 34, 45, 0.2)";
                ctx.fillRect(startPixel, top, endPixel - startPixel, height);
                ctx.strokeRect(startPixel, top, 0, height);
                ctx.strokeRect(endPixel, top, 0, height);
                ctx.restore();
              },
            },
          ]}
          options={{
            animation: {
              duration: 0,
            },
            scales: {
              x: {
                ticks: {
                  callback(_, index) {
                    return index !== 0
                      ? getDateTimeUNIX(
                          parseInt(this.getLabelForValue(index)) * 1000
                        )
                      : "";
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0,
                  maxTicksLimit: 3,
                },
              },
              y: {
                ticks: {
                  count: 5,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                position: "average",
                callbacks: {
                  title: (context) => {
                    return getDateTimeUNIX(parseInt(context[0].label) * 1000);
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default RenderChart;
