import { EditOutlined } from "@ant-design/icons";
import { Button, Drawer, Empty, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Note } from ".";
import Card from "./Card";
import RenderNote from "../Events/RenderNote";
import EditNote from "./EditNote";
import { getIncidentSummary } from "./service";
import { useParams } from "react-router";

export const style = createUseStyles({
  cont: {
    marginBottom: "0.7rem",
  },
  title: {
    padding: "0.6rem",
    background: "#ECECF3",
    color: "#262626",
  },
  editor: {
    width: "90%",
    maxHeight: "15rem",
    padding: "0.5rem 0.7rem",
    overflow: "hidden",
    margin: "1rem",
    boxShadow: "0px 0px 10px 3px rgba(230,230,230,0.69)",
    borderRadius: 5,
  },
  updateCont: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  update: {
    fontSize: "0.8rem",
    backgroundColor: "white",
    borderColor: "#1890FF",
    color: "#1890FF",
    padding: "0.2rem 0.5rem",
    margin: "0.4rem 0.4rem",
  },
  view: {
    fontSize: "0.8rem",
    borderColor: "#1890FF",
    padding: "0.2rem 0.5rem",
    margin: "0.4rem 0.4rem",
  },
  linkCont: {
    paddingTop: "0.4rem",
  },
  editorTitle: {
    padding: "2.5px 5px",
    margin: "10px 0",
    fontSize: "0.75rem",
    fontWeight: 500,
    alignSelf: "flex-start",
    display: "flex",
    width: 100,
    justifyContent: "flex-start",
    background: "rgba(24, 144, 255, 0.1)",
    borderRadius: "5px",
    borderLeft: "6px solid #1890FF",
  },
  timestamp: {
    fontSize: "0.75rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export interface Summary {
  id?: string;
  timestamp?: string;
  note?: Note;
}

const Playbook = () => {
  const [globalSummary, setGlobalSummary] = useState<Note | undefined>();
  const [globalSummaryLoading, setGlobalSummaryLoading] = useState(true);
  const [summaries, setSummaries] = useState<Summary[]>([]);
  const [note, setNote] = useState<Note>();
  const [isView, setIsView] = useState(
    globalSummary?.cause !== "" || globalSummary?.resolution !== ""
  );
  const [visible, setVisible] = useState(false);
  const [isGlobal, setIsGlobal] = useState(false);

  const classes = style();
  const { incident_id } = useParams();

  useEffect(() => {
    const fetchGlobalSummary = async () => {
      try {
        const { summary, allSummaries } = await getIncidentSummary(incident_id);
        setGlobalSummary(summary);
        setSummaries(allSummaries);
        setGlobalSummaryLoading(false);
      } catch (error) {
        setGlobalSummaryLoading(false);
        console.error(error);
      }
    };
    fetchGlobalSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsView(globalSummary ? true : false);
  }, [globalSummary, visible]);

  const renderUpdate = () => {
    return (
      <div className={classes.updateCont}>
        <Button
          onClick={() => setIsView(false)}
          className={classes.update}
          type="primary"
          icon={<EditOutlined />}
        >
          Update
        </Button>
      </div>
    );
  };

  const handleGlobalSummaryClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setNote(globalSummary);
    setVisible(true);
    setIsGlobal(true);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className={classes.cont}>
        <div className={classes.title}>Global Summary</div>
        {!globalSummaryLoading ? (
          <div>
            {globalSummary ? (
              <Card
                handleClick={handleGlobalSummaryClick}
                setVisible={setVisible}
                setNote={setNote}
                summary={{ note: globalSummary }}
              />
            ) : (
              <Empty style={{ margin: "1rem" }} />
            )}
            <div className={classes.updateCont}>
              <Button
                onClick={(e) => handleGlobalSummaryClick(e)}
                className={classes.view}
                type="primary"
              >
                {globalSummary ? "View Summary" : "Add Summary"}
              </Button>
            </div>
          </div>
        ) : (
          <Spin
            style={{
              width: "100%",
              height: "12rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        )}
      </div>
      <div style={{ marginBottom: 10 }}>
        <div className={classes.title}>
          Recent Resolution Notes ({summaries.length}):{" "}
        </div>
        <div className={classes.linkCont}>
          {summaries?.map((summary, index) => {
            if (summary?.note?.cause === "" && summary?.note?.resolution === "")
              return null;
            return (
              <Card
                handleClick={() => {
                  setNote(summary.note);
                  setVisible(true);
                  setIsGlobal(false);
                }}
                setVisible={setVisible}
                setNote={setNote}
                key={index}
                summary={summary}
              />
            );
          })}
        </div>
      </div>
      <Drawer
        title={`${isView ? "" : "Add"} ${
          isGlobal ? " Global Summary" : "Event Note"
        }`}
        placement="right"
        onClose={() => setVisible(false)}
        visible={visible}
        width={"100vw"}
        extra={<>{isView && isGlobal ? renderUpdate() : ""}</>}
      >
        {!isView ? (
          <EditNote
            setNote={setGlobalSummary}
            note={globalSummary}
            setVisible={setVisible}
          />
        ) : (
          <RenderNote setView={setIsView} note={note} />
        )}
      </Drawer>
    </div>
  );
};

export default Playbook;
