import { Source, SubItem } from "../../Sidebar";
import { v4 as uuid } from "uuid";
import { concat } from "lodash";

interface RoutesItem {
  name: Source;
  route: SubItem;
}

export const allIncidentsList: ({
  incidentId,
  eventId,
}: {
  incidentId?: string;
  eventId?: string;
}) => SubItem[] = () => [
  {
    label: "Incidents",
    children: [
      {
        label: "All",
        path: "/incidents/all",
        key: uuid().slice(0, 8),
      },
      {
        label: "Open",
        path: "/incidents/open",
        key: uuid().slice(0, 8),
      },
      {
        label: "Close",
        path: "/incidents/close",
        key: uuid().slice(0, 8),
      },
    ],
    key: uuid().slice(0, 8),
  },
  {
    label: "Action Requests",
    path: "/incidents/action",
    key: uuid().slice(0, 8),
  },
];

export const singleIncidentList: ({
  incidentId,
  eventId,
  source,
}: {
  incidentId?: string;
  eventId?: string;
  source?: Source[];
}) => SubItem[] = ({ incidentId, eventId, source }) => {
  const routes: SubItem[] = [
    {
      label: "Overview",
      path: `/incidents/${incidentId || 0}/${eventId || 0}/overview`,
      key: uuid().slice(0, 8),
    },
  ];

  let extraRoutes: SubItem[] = [];
  Routes(incidentId, eventId).forEach((routItems) => {
    if (source?.includes(routItems.name)) {
      extraRoutes = [...extraRoutes, routItems.route];
    }
  });
  const allRoutes = concat(routes, extraRoutes);
  allRoutes.push({
    label: "Events",
    path: `/incidents/${incidentId || 0}/events`,
    key: uuid().slice(0, 8),
  });
  return allRoutes || [];
};

let Routes: (incidentId?: string, eventId?: string) => RoutesItem[] = (
  incidentId,
  eventId
) => [
  {
    name: "POSTGRES",
    route: {
      label: "Pg_stat",
      path: `/incidents/${incidentId || 0}/${eventId || 0}/pg_stat`,
      key: uuid().slice(0, 8),
    },
  },
  {
    name: "NEWRELIC_APDEX",
    route: {
      label: "New Relic",
      path: `/incidents/${incidentId || 0}/${eventId || 0}/new_relic`,
      key: uuid().slice(0, 8),
    },
  },
  {
    name: "RDS",
    route: {
      label: "RDS",
      path: `/incidents/${incidentId || 0}/${eventId || 0}/rds`,
      key: uuid().slice(0, 8),
    },
  },
  {
    name: "REDIS",
    route: {
      label: "Reddis",
      path: `/incidents/${incidentId || 0}/${eventId || 0}/reddis`,
      key: uuid().slice(0, 8),
    },
  },
  {
    name: "NEWRELIC_SYNTHETICS",
    route: {
      label: "Synthetics",
      path: `/incidents/${incidentId || 0}/${eventId || 0}/synthetics`,
      key: uuid().slice(0, 8),
    },
  },
  {
    name: "ECS",
    route: {
      label: "ECS",
      path: `/incidents/${incidentId || 0}/${eventId || 0}/ecs`,
      key: uuid().slice(0, 8),
    },
  },
];
