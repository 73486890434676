import * as React from "react";
import { SLACK_URL } from "../../globals";

const Login = () => {
  const handleClick = () => {
    window.location.href = SLACK_URL;
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        style={{ cursor: "pointer" }}
        onClick={handleClick}
        alt="Sign in with Slack"
        height="40"
        width="172"
        src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
        srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
      />
    </div>
  );
};

export default Login;
