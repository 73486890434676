import { Event, Note } from ".";
import { GET, POST } from "../../../../services/apiHandler";
import { getDuration, getStartTime } from "../../../common/utils";
import { User } from "../../../ViewList";
import {
  FETCH_EVENTS,
  FETCH_EVENTS_NOTES,
  FETCH_EVENTS_VIEWS,
} from "./constants";

export const getEventsData = async (incident_id?: string) => {
  try {
    const { isSuccess, errorMessage, data } = await GET(
      `${FETCH_EVENTS(incident_id)}`
    );
    if (!isSuccess) throw errorMessage;
    let events: Event[] = [];
    let idList: string[] = [];

    data.events.forEach((event: any) => {
      idList.push(event.id);
      const duration = getDuration(event.startTime, event.endTime);
      events = [
        ...events,
        {
          key: event?.id,
          since: duration,
          name: event?.name,
          notes: event?.isResolutionNotePresent && {},
          status: event?.status === "CLOSED" ? "CLOSED" : "OPEN",
          startTime: `#${event?.opsgenieId}: ` + getStartTime(event?.startTime),
          view: [],
        },
      ];
    });

    return { eventData: events, idList };
  } catch (error) {
    throw error;
  }
};

export const getEventsViewsData = async ({
  incident_id,
  eventIdList,
}: {
  incident_id?: string;
  eventIdList: string[];
}) => {
  try {
    const { isSuccess, errorMessage, data } = await POST(
      `${FETCH_EVENTS_VIEWS(incident_id)}`,
      { eventIdList }
    );
    if (!isSuccess) throw errorMessage;

    let viewData: User[][] = [];
    data.forEach((view: any) => {
      let arr: User[] = [];
      view.views.forEach((v: any) => {
        arr.push({
          email: v.email,
          name: v.name,
          picURL: v.profilePicUrl,
        });
      });
      viewData.push(arr);
    });
    return { viewData };
  } catch (error) {
    throw error;
  }
};

export const getEventsNotesData = async ({
  incident_id,
  event_id,
}: {
  incident_id: string;
  event_id: string;
}) => {
  try {
    const { isSuccess, errorMessage, data } = await GET(
      `${FETCH_EVENTS_NOTES(incident_id, event_id)}`
    );
    if (!isSuccess) throw errorMessage;
    const note: Note = {
      cause: data.note.cause,
      resolution: data.note.resolution,
    };
    return { note };
  } catch (error) {
    throw error;
  }
};

export const postEventsNotesData = async ({
  incident_id,
  event_id,
  cause,
  resolution,
}: {
  incident_id: string;
  event_id: string;
  cause?: string;
  resolution?: string;
}) => {
  try {
    const { isSuccess, errorMessage, data } = await POST(
      `${FETCH_EVENTS_NOTES(incident_id, event_id)}`,
      {
        cause: cause || "",
        resolution: resolution || "",
      }
    );
    if (!isSuccess) throw errorMessage;

    return data;
  } catch (error) {
    throw error;
  }
};
