const SLACK_USER_SCOPE = "chat:write,users:read.email,users:read";
const SLACK_CLIENT_ID = "4737242421.3279404757285";
const DASHBOARD_BASE_URL = "https://tarzandashboard.shipsy.in";
const REDIRECT_URL = `${DASHBOARD_BASE_URL}/oauth`;

const config = {
  default: "TARZAN_BASE_URL",
  SLACK_URL: `https://slack.com/oauth/v2/authorize?user_scope=${SLACK_USER_SCOPE}&client_id=${SLACK_CLIENT_ID}&redirect_uri=${REDIRECT_URL}`,
  API_BASE_URL: "https://tarzan.shipsy.in",
};
export default config;
