import React from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import withStyles from "react-jss";

const styles = (theme: any) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
  },
  nameTag: {
    height: 35,
    width: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    background: "rgba(24, 144, 255, 0.1)",
    fontSize: "1rem",
    fontWeight: 400,
    color: "#262626",
  },
  dropItem: {
    padding: "2px 20px",
  },
});

interface IProps {
  classes: {
    [T in keyof ReturnType<typeof styles>]: string;
  };
}

const Profile: React.FC<IProps> = ({ classes }) => {
  const name = useSelector((state: any) => state.user.name);
  const profilePicUrl = useSelector((state: any) => state.user.profilePicUrl);
  const menu = (
    <Menu
      items={[
        {
          key: 1,
          label: (
            <Link className={classes.dropItem} to="/logout">
              Logout
            </Link>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <div className={classes.container}>
        {profilePicUrl === "" ? (
          <div className={classes.nameTag}>
            {name?.[0]?.toUpperCase() || "S"}
          </div>
        ) : (
          <img
            className={classes.nameTag}
            src={profilePicUrl}
            alt="ProfilePic"
          />
        )}
        <Dropdown overlay={menu} placement="bottomRight" arrow>
          <KeyboardArrowDownRoundedIcon color="primary" />
        </Dropdown>
      </div>
    </>
  );
};

export default withStyles(styles)(Profile);
