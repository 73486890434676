import { EventData } from ".";
import { users } from "../../../ViewList/utils";
import { Summary } from "./Playbook";
import { User } from "./Warzone";

export const data: EventData = {
  id: "1",
  description:
    "Threshold Crossed: 3 out of the last 3 datapoints [9335.10076803132 (19/05/22 11:24:00), 9586.734346956546 (19/05/22 11:19:00), 7750.5651656507125 (19/05/22 11:14:00)] were greater than or equal to the threshold (7000.0) (minimum 3 datapoints for OK -> ALARM transition).",
  name: `"awsrds-wms-shipsy-High-Total-IOPS" in US West (Oregon)`,
  notes: {
    cause: `This is the cause \n\n ![](https://i.stack.imgur.com/SY5hu.png) `,
    resolution: "## Resolution Description",
  },
  start: "May 9, 2022 | 08:50:02 AM IST",
  since: "1h 12m",
  status: "OPEN",
  views: users,
};

export const summariesData: Summary[] = [
  {
    id: "1",
    timestamp: "May 9, 2022 | 08:50:02 AM IST",
    note: {
      cause: `This is the cause \n\n ![](https://i.stack.imgur.com/SY5hu.png) `,
      resolution: "## Resolution Description",
    },
  },
  {
    id: "2",
    timestamp: "May 9, 2022 | 08:50:02 AM IST",
    note: {
      cause: `This is the cause  `,
      resolution:
        "- help with dto \n - Integrate with backend \n - [x] Complete Overview",
    },
  },
  {
    id: "3",
    timestamp: "June 19, 2022 | 08:50:02 AM IST",
    note: {
      cause: `This is the cause \n\n ![](https://i.stack.imgur.com/SY5hu.png) `,
      resolution: "## Resolution Description",
    },
  },
  {
    id: "4",
    timestamp: "May 10, 2022 | 08:50:02 AM IST",
    note: {
      cause: ``,
      resolution:
        "This is the cause \n\n ![](https://i.stack.imgur.com/SY5hu.png) ",
    },
  },
];

export const warzoneData: User[] = [
  {
    key: "1",
    name: "Sreyash Kumar",
    email: "sreyas.kumar@shipsy.io",
    lastJoined: "7m ago",
    profilePic:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3i_qZtrjSgoPCyIOywhlX8MKOzRIaQbKU0A&usqp=CAU",
    type: "Admin",
  },
  {
    key: "2",
    name: "Sreyash Kumar",
    email: "sreyas.kumar@shipsy.io",
    lastJoined: "7m ago",
    profilePic:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3i_qZtrjSgoPCyIOywhlX8MKOzRIaQbKU0A&usqp=CAU",
    type: "Admin",
  },
  {
    key: "3",
    name: "Sreyash Kumar",
    email: "sreyas.kumar@shipsy.io",
    lastJoined: "7m ago",
    profilePic:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3i_qZtrjSgoPCyIOywhlX8MKOzRIaQbKU0A&usqp=CAU",
    type: "Admin",
  },
  {
    key: "4",
    name: "Sreyash Kumar",
    email: "sreyas.kumar@shipsy.io",
    lastJoined: "7m ago",
    profilePic:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3i_qZtrjSgoPCyIOywhlX8MKOzRIaQbKU0A&usqp=CAU",
    type: "Admin",
  },
  {
    key: "5",
    name: "Sreyash Kumar",
    email: "sreyas.kumar@shipsy.io",
    lastJoined: "7m ago",
    profilePic:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3i_qZtrjSgoPCyIOywhlX8MKOzRIaQbKU0A&usqp=CAU",
    type: "Admin",
  },
  {
    key: "6",
    name: "Sreyash Kumar",
    email: "sreyas.kumar@shipsy.io",
    lastJoined: "7m ago",
    profilePic:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3i_qZtrjSgoPCyIOywhlX8MKOzRIaQbKU0A&usqp=CAU",
    type: "Admin",
  },
];
