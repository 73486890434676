import React from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import { externalTooltipHandler } from "../../../common/tooltip";
import { Spin } from "antd";

ChartJS.register(...registerables);

const IncidentGraph = ({
  count,
  label,
  graphLoading,
}: {
  label?: string[];
  count?: number[];
  graphLoading?: boolean;
}) => {
  const labels: string[] = label || [];

  const data = {
    labels,
    datasets: [
      {
        type: "bar" as const,
        label: "Count",
        backgroundColor: "#FACC71",
        minBarLength: 5,
        data: count || [],
      },
    ],
  };

  return (
    <div
      style={{
        margin: "20px auto",
        height: "80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {!graphLoading ? (
        <Chart
          style={{ zIndex: 1, padding: "20px 10px" }}
          type="bar"
          options={{
            animation: {
              duration: 0,
            },
            scales: {
              x: {
                display: false,
              },
              y: {
                display: false,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                position: "nearest",
                external: externalTooltipHandler,
              },
            },
            maintainAspectRatio: false,
          }}
          data={data}
        />
      ) : (
        <Spin size="small" />
      )}
    </div>
  );
};

export default IncidentGraph;
