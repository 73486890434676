import * as React from "react";

const withAuth = (Component: any) => {
  return class WithAuth extends React.PureComponent<any, any> {
    state = {
      isAuthenticated: false,
      isLoading: true,
    };

    async componentDidMount() {
      const storage = window.localStorage;
      const isAuthenticated =
        storage?.getItem("userId") && storage?.getItem("authToken");
      this.setState({
        isLoading: false,
        isAuthenticated,
      });
    }

    render() {
      const { isLoading, isAuthenticated } = this.state;
      if (isLoading) {
        return null;
      }
      return <Component {...this.props} authenticated={isAuthenticated} />;
    }
  };
};

export default withAuth;
