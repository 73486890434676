import { Tabs } from "antd";
import React from "react";
import { ChartData } from ".";
import RenderChart from "./RenderChart";

const RenderTopTransaction = ({
  transactionData,
  title,
}: {
  transactionData: ChartData[];
  title: string;
}) => {
  const { TabPane } = Tabs;
  return (
    <>
      <div style={{ padding: "1rem", fontSize: "0.9rem" }}>
        {`${title} : `}
        <div
          style={{ fontWeight: 300 }}
        >{`( ${transactionData[0]?.subtitle} )`}</div>
      </div>
      <Tabs defaultActiveKey="0" centered>
        {transactionData.map((transaction, index) => {
          return (
            <TabPane
              style={{ flexGrow: 1 }}
              tab={transaction?.title}
              key={index}
            >
              <RenderChart
                width={transaction?.width}
                url={transaction?.link}
                title={transaction?.title}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </>
  );
};
export default RenderTopTransaction;
