import { User } from "../../reducer/user.reducer";
import { POST } from "../../services/apiHandler";
import { SLACK_AUTH } from "./constants";

export const callSlackCallback = async ({
  code,
  error = "",
}: {
  code: string;
  error?: string;
}) => {
  try {
    const { isSuccess, errorMessage, data } = await POST(`${SLACK_AUTH}`, {
      code: code,
      error: error,
    });

    if (!isSuccess) throw errorMessage;
    const user: User = {
      authToken: data.token,
      userId: data.id,
      email: data.email,
      isActive: data.isActive,
      name: data.name,
      profilePicUrl: data.profilePicUrl,
      role: data.role,
      slackId: data.slackId,
      slackOuthToken: data.slackOuthToken,
    };
    return { user };
  } catch (error) {
    throw error;
  }
};
