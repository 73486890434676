import { NavItem } from "./Navbar";

export const getFileExtension = (filename: string) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? "" : ext[1];
};

export const dataHeaders: Record<string, string> = {
  JPG: "image/*",
  JPEG: "image/*",
  PNG: "image/*",
  PDF: "application/*",
  DOCX: "application/*",
  XLSX: "application/*",
  CSV: "text/csv",
  XLS: "application/*",
  DOC: "application/*",
};

export const navlist: NavItem[] = [
  {
    path: "/incidents",
    tag: "Incidents",
  },
  {
    path: "/insights",
    tag: "Insights",
  },
  {
    path: "/mttr",
    tag: "MTTR",
  },
];
