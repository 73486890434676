import { createSlice } from "@reduxjs/toolkit";

export interface User {
  userId: string;
  email: string;
  isActive: string;
  name: string;
  profilePicUrl: string;
  role: string;
  slackId: string;
  slackOuthToken: string;
  authToken: string;
}

export const defaultState: User = {
  userId: localStorage.getItem("userId") || "",
  authToken: localStorage.getItem("authToken") || "",
  email: localStorage.getItem("email") || "",
  isActive: localStorage.getItem("isActive") || "",
  name: localStorage.getItem("name") || "",
  profilePicUrl: localStorage.getItem("profilePicUrl") || "",
  role: localStorage.getItem("role") || "",
  slackId: localStorage.getItem("slackId") || "",
  slackOuthToken: localStorage.getItem("slackOuthToken") || "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: defaultState,
  reducers: {
    setUser: (state: User, action: { payload: User }) => {
      state = action.payload;
    },
    setName: (state: User, action: any) => {
      state.name = action.payload;
    },
    setUserId: (state: User, action: any) => {
      state.userId = action.payload;
    },
    setProfilePicUrl: (state: User, action: any) => {
      state.profilePicUrl = action.payload;
    },
  },
});

export default userSlice;
