import React from "react";
import styles from "./styles";

const PgStat = () => {
  const classes = styles();

  return (
    <div className={classes.mainContainer}>
      <div></div>
      <div className={classes.container}>
        <div className={classes.head}>
          <div className={classes.title}>Pg Stat</div>
        </div>
        under-development
      </div>
    </div>
  );
};

export default PgStat;
