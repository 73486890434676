import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0 0 20px",
    height: "100%",
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "0 20px",
    background: "#FFFFFF",
    flexGrow: 1,
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
  },
  title: {
    fontWeight: 500,
    fontSize: "1.2rem",
    padding: "15px 2px ",
    display: "flex",
    alignItems: "center",
  },
  name: {
    fontSize: "0.95rem",
    fontWeight: 300,
    marginLeft: 10,
  },
  tableContainer: {
    overflow: "scroll",
    flexGrow: 1,
    position: "relative",
  },
  header: {
    padding: "10px",
    fontWeight: 600,
    fontSize: "0.75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  incidentCont: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    fontSize: "0.7rem",
    "& div": {
      display: "flex",
      alignItems: "center",
    },
    "& > div": {
      maxWidth: 300,
    },
  },
  alarmCont: {},
  alarm: {
    fontWeight: 600,
    minWidth: 80,
  },
  message: {
    padding: "5px 0",
    fontWeight: 300,
    flexDirection: "column",
    overflow: "hidden",
    marginBottom: 5,
    transition: "0.3s all",
  },
  detail: {
    fontWeight: 300,
    padding: "0 2.5px",
  },
  expand: {
    fontWeight: 300,
    width: "30%",
    color: "#096DD9",
    padding: "5px 0",
    cursor: "pointer",
  },
  rowItem: {
    fontSize: "0.75rem",
    fontWeight: 300,
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  updateCont: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  update: {
    width: "6.5rem",
    backgroundColor: "white",
    borderColor: "#1890FF",
    color: "#1890FF",
  },
});

export default styles;
