import React, { CSSProperties, useState } from "react";
import { Note } from ".";
import MDEditor, { ContextStore } from "@uiw/react-md-editor";
import { Button, Spin } from "antd";
import { createUseStyles } from "react-jss";
import { postIncidentSummary } from "./service";
import { useParams } from "react-router";
import { openNotification } from "../../../common/utils";

const style = createUseStyles({
  container: { height: "100%", display: "flex", flexDirection: "column" },
  editorsCont: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  editorCont: {
    height: "90%",
    width: "48%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: { padding: "10px 0", fontSize: "1rem", fontWeight: 600 },
  editor: { marginBottom: 20, width: "100%" },
  buttonCont: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 10,
    alignSelf: "center",
    position: "relative",
  },
  button: { width: "12rem", padding: 10, height: "inherit" },
  info: {
    position: "absolute",
    top: 0,
    left: 0,
    fontSize: "0.75rem",
  },
});

const EditNote = ({
  note,
  setNote,
  setVisible,
}: {
  note?: Note;
  setNote: any;
  setVisible: any;
}) => {
  const [cause, setCause] = useState(note?.cause);
  const [resolution, setResolution] = useState(note?.resolution);
  const [loading, setLoading] = useState(false);

  const classes = style();

  const { incident_id } = useParams();

  const handleClick = () => {
    const postSummary = async () => {
      if (cause || resolution) {
        setLoading(true);
        const { summary } = await postIncidentSummary(
          cause,
          resolution,
          incident_id
        );
        setNote({
          cause: summary?.cause,
          resolution: summary?.resolution,
        });
        setLoading(false);
        openNotification("success", !note ? "Note Saved" : "Note Updated");
      }
      setVisible(false);
    };

    postSummary();
  };

  const editorConfig = (val: any, setFunc: any) => ({
    height: global.window.innerHeight * 0.7,
    value: val,
    onChange: (data: any) => setFunc(data),
    enableScroll: false,
    onHeightChange: (
      value?: CSSProperties["height"],
      oldValue?: CSSProperties["height"],
      state?: ContextStore
    ) => {
      if (state !== undefined) {
        state.height = Number(oldValue);
      }
    },
  });

  return (
    <div className={classes.container}>
      <Spin spinning={loading} size={"large"}>
        <div className={classes.editorsCont}>
          <div className={classes.editorCont} data-color-mode="light">
            <div className={classes.title}>CAUSE</div>
            <MDEditor
              className={classes.editor}
              {...editorConfig(cause, setCause)}
              preview="edit"
            />
          </div>
          <div className={classes.editorCont} data-color-mode="light">
            <div className={classes.title}>RESOULUTION</div>
            <MDEditor
              className={classes.editor}
              {...editorConfig(resolution, setResolution)}
              preview="edit"
            />
          </div>
        </div>
        <div className={classes.buttonCont}>
          <div className={classes.info}>
            *This editor uses markdown, visit{" "}
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://www.markdownguide.org/basic-syntax/"
            >
              MarkDown Guide
            </a>{" "}
            for reference.
          </div>
          <Button
            onClick={() => handleClick()}
            className={classes.button}
            type="primary"
          >
            {!note ? "Save" : "Update"} Note
          </Button>
        </div>
      </Spin>
    </div>
  );
};

export default EditNote;
