import React from "react";
import { VideoCameraOutlined } from "@ant-design/icons";

const RenderJoin = ({ link }: { link?: string }) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    window.open("https://meet.google.com/cio-gbpd-tkb?authuser=0", "_blank");
  };

  return (
    <div
      style={{
        width: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 0",
        cursor: "pointer",
      }}
      onClick={(e) => handleClick(e)}
    >
      <div
        style={{
          padding: 5,
          borderRadius: 5,
          fontWeight: 300,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          width: "60px",
          margin: "0 auto",
          background: "#1890FF",
          color: "#FFFF",
          fontSize: "0.75rem",
        }}
      >
        <VideoCameraOutlined
          style={{ fontSize: "1rem", transform: "scaleX(-1)" }}
        />
        <div style={{ marginLeft: 5 }}>Join</div>
      </div>
    </div>
  );
};

export default RenderJoin;
