import { Spin } from "antd";
import React, { useEffect } from "react";
import queryString from "querystring";
import url from "url";
import { useNavigate } from "react-router";
import { callSlackCallback } from "./service";
import { openNotification } from "../common/utils";
import { User } from "../../reducer/user.reducer";
import { setUser } from "../../reducer";

const Oauth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const getIdentity = async () => {
      try {
        const { code, error } = await redirectToSlack();
        if (code === "") {
          navigate("/login", { replace: true });
          return;
        }
        const { user } = await callSlackCallback({ code, error });
        // setting user attributed in local storage
        user && setStorage(user);
        // setting user in redux storage
        user && setUser(user);
        navigate("/", { replace: true });
      } catch (error) {
        openNotification("error", `Error Loging In : ${error}`);
        navigate("/login", { replace: true });
      }
    };
    getIdentity();
  }, []); // eslint-disable-line

  const setStorage = (user: User) => {
    const storage = window.localStorage;
    storage.setItem("authToken", user.authToken);
    storage.setItem("email", user.email);
    storage.setItem("userId", user.userId);
    storage.setItem("isActive", user.isActive);
    storage.setItem("name", user.name);
    storage.setItem("profilePicUrl", user.profilePicUrl);
    storage.setItem("role", user.role);
    storage.setItem("slackId", user.slackId);
    storage.setItem("slackOuthToken", user.slackOuthToken);
  };
  const redirectToSlack = async () => {
    const { location } = window;

    if (!location) {
      return { error: "", code: "" };
    }
    const { href = "" } = location;
    const { query } = url.parse(href);

    if (query) {
      const parsedQuery = queryString.parse(query);
      const { code, error = "" } = parsedQuery;
      const reqBody = {
        code: (code as string) || "",
        error: (error as string) || "",
      };
      return { error: reqBody.error, code: reqBody.code };
    } else {
      return { error: "", code: "" };
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default Oauth;
