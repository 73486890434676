import React, { useState } from "react";
import styles from "./styles";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { IncidentType } from ".";

const RenderIncident = (incident: IncidentType) => {
  const { id, alarm, name, message, start } = incident;
  const classes = styles();
  const [expand, setExpand] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setExpand(!expand);
  };

  return (
    <div className={classes.incidentCont}>
              {start}
      <div className={classes.alarmCont}>
        <div className={classes.name}>
          <div className={classes.alarm}>{`#${alarm}: `}
            <Link to={`/incidents/${id}/0/overview`}>{name}</Link>
          </div>
        </div>
      </div>
      <div
        className={classes.message}
        style={{ height: expand ? "150px" : "70px" }}
      >
        {message || "No Description"}
      </div>
      <div className={classes.expand} onClick={(e) => handleClick(e)}>
        View {expand ? "Less" : "More"}
        <DownOutlined
          style={{
            fontSize: "0.6rem",
            padding: "0 5px",
            transform: expand ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "0.5s all",
          }}
        />
      </div>
    </div>
  );
};

export default RenderIncident;
