import { API_BASE_URL } from "../../../../globals";

export const FETCH_INTCIDENT = `${API_BASE_URL}/incidents`;
export const FETCH_EVENT = (incident_id: any, event_id: any) =>
  `${API_BASE_URL}/incidents/${incident_id}/events/overview?${
    event_id === "0" ? "" : `eventId=${event_id}`
  }`;
export const FETCH_EVENTS_VIEWS = (incident_id: any, event_id: any) =>
  `${API_BASE_URL}/incidents/${incident_id}/events/views?${
    event_id === "0" ? "" : `eventId=${event_id}`
  }`;
export const FETCH_EVENTS_NOTES = (incident_id: any) =>
  `${API_BASE_URL}/incidents/${incident_id}/events/notes`;
export const FETCH_INTCIDENT_SUMMARY = (incident_id: any) =>
  `${API_BASE_URL}/incidents/${incident_id}/summary`;
export const FETCH_WARZONE_DATA = (incident_id: any, event_id: any) =>
  `${API_BASE_URL}/incidents/${incident_id}/events/warzone?${
    event_id === "0" ? "" : `eventId=${event_id}`
  }`;
export const FETCH_WARZONE_LINK = (incident_id: any, event_id: any) =>
  `${API_BASE_URL}/incidents/${incident_id}/events/warzone/link?${
    event_id === "0" ? "" : `eventId=${event_id}`
  }`;
