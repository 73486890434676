import moment from "moment";
import { DataType } from ".";
import { users } from "../../../ViewList/utils";

export const data: DataType[] = [
  {
    key: 1,
    count: 5,
    incident: {
      alarm: "755 ",
      name: "awsrds-wms-shipsy-High-Total",
      message:
        "Threshold Crossed: 3 out of the last 3 datapoints [9695.36427119575 (18/05/22 10:57.625935650138 (18/05/22 10:52:00), 7668.05868206157 (18/05/22 10:47:00)] were greater than or equal to the threshold (7000.0) (minimum 3 datapoints for OK -> ALARM transition).",
      priority: "P3",
      routed_teams: [
        "Logistics-SRE",
        "Logistics-SRE",
        "Logistics-SRE",
        "Logistics-SRE",
      ],
    },
    link: "https://www.google.com",
    mttr: "43m",
    since: "1h 57m",
    status: "OPEN",
    views: users,
  },
  {
    key: 2,
    incident: {
      alarm: "755 ",
      name: "awsrds-wms-shipsy-High-Total-Space",
      message:
        "Threshold Crossed: 3 out of the last 3 datapoints [9695.36427119575 (18/05/22 10:57.625935650138 (18/05/22 10:52:00), 7668.05868206157 (18/05/22 10:47:00)] were greater than or equal to the threshold (7000.0) (minimum 3 datapoints for OK -> ALARM transition).",
      priority: "P3",
      routed_teams: ["Logistics-SRE"],
    },
    count: 5,
    link: "https://www.google.com",
    mttr: "43m",
    since: "1h 57m",
    status: "CLOSED",
    views: users,
  },
  {
    key: 3,
    incident: {
      alarm: "755 ",
      name: "awsrds-wms-shipsy-High-Total-Space",
      message:
        "Threshold Crossed: 3 out of the last 3 datapoints [9695.36427119575 (18/05/22 10:57.625935650138 (18/05/22 10:52:00), 7668.05868206157 (18/05/22 10:47:00)] were greater than or equal to the threshold (7000.0) (minimum 3 datapoints for OK -> ALARM transition).",
      priority: "P3",
      routed_teams: ["Logistics-SRE"],
    },
    count: 5,
    link: "https://www.google.com",
    mttr: "43m",
    since: "1h 57m",
    status: "OPEN",
    views: users,
  },
  {
    key: 4,
    incident: {
      alarm: "755 ",
      name: "awsrds-wms-shipsy-High-Total-Space",
      message:
        "Threshold Crossed: 3 out of the last 3 datapoints [9695.36427119575 (18/05/22 10:57.625935650138 (18/05/22 10:52:00), 7668.05868206157 (18/05/22 10:47:00)] were greater than or equal to the threshold (7000.0) (minimum 3 datapoints for OK -> ALARM transition).",
      priority: "P3",
      routed_teams: ["Logistics-SRE"],
    },
    count: 5,
    link: "https://www.google.com",
    mttr: "43m",
    since: "1h 57m",
    status: "OPEN",
    views: users,
  },
  {
    key: 5,
    incident: {
      alarm: "755 ",
      name: "awsrds-wms-shipsy-High-Total-Space",
      message:
        "Threshold Crossed: 3 out of the last 3 datapoints [9695.36427119575 (18/05/22 10:57.625935650138 (18/05/22 10:52:00), 7668.05868206157 (18/05/22 10:47:00)] were greater than or equal to the threshold (7000.0) (minimum 3 datapoints for OK -> ALARM transition).",
      priority: "P3",
      routed_teams: ["Logistics-SRE"],
    },
    count: 5,
    link: "https://www.google.com",
    mttr: "43m",
    since: "1h 57m",
    status: "OPEN",
    views: users,
  },
  {
    key: 6,
    incident: {
      alarm: "755 ",
      name: "awsrds-wms-shipsy-High-Total-Space",
      message:
        "Threshold Crossed: 3 out of the last 3 datapoints [9695.36427119575 (18/05/22 10:57.625935650138 (18/05/22 10:52:00), 7668.05868206157 (18/05/22 10:47:00)] were greater than or equal to the threshold (7000.0) (minimum 3 datapoints for OK -> ALARM transition).",
      priority: "P3",
      routed_teams: ["Logistics-SRE"],
    },
    count: 5,
    link: "https://www.google.com",
    mttr: "43m",
    since: "1h 57m",
    status: "CLOSED",
    views: users,
  },
];

export function getLabel(datapoint: any) {
  let newLabel = "";
  if (datapoint.startDate === datapoint.endDate) {
    newLabel = moment.unix(datapoint.startDate).format("MMM Do, YYYY");
  } else {
    const l1 = moment.unix(datapoint.startDate).format("MMM Do");
    const l2 = moment.unix(datapoint.endDate).format("MMM Do");
    newLabel = `${l1} - ${l2} '${moment
      .unix(datapoint.startDate)
      .format("YY")}`;
  }
  return newLabel;
}
