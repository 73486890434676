import { combineReducers } from "redux";
import userSlice from "./user.reducer";

const rootReducer = combineReducers({
  user: userSlice.reducer,
});

export const { setUserId, setName, setProfilePicUrl, setUser } =
  userSlice.actions;
export default rootReducer;
