import React from "react";
import { createUseStyles } from "react-jss";

const RenderGraphHeader = ({
  header,
  isDay,
  setIsDay,
}: {
  header: string;
  isDay: boolean;
  setIsDay: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const styles = createUseStyles({
    cont: {
      padding: "0 10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "0.7rem",
    },
    header: {
      fontWeight: 600,
    },
    tabCont: {
      display: "flex",
    },
    tab: {
      padding: "2px 5px",
      margin: 5,
      fontWeight: 300,
      fontSize: "0.7rem",
      border: "1px solid #595959",
      color: "#595959",
      borderRadius: 5,
      cursor: "pointer",
      //   background : ({isActive} : any)=> isActive ? "#595959" : "inherit"
    },
  });
  const classes = styles();

  return (
    <div className={classes.cont}>
      <div className={classes.header}>{header}</div>
      <div className={classes.tabCont}>
        <div
          className={classes.tab}
          style={{
            background: isDay ? "#595959" : "inherit",
            color: isDay ? "white" : "#595959",
          }}
          onClick={() => setIsDay(true)}
        >
          15d
        </div>
        <div
          className={classes.tab}
          style={{
            background: !isDay ? "#595959" : "inherit",
            color: !isDay ? "white" : "#595959",
          }}
          onClick={() => setIsDay(false)}
        >
          3mo
        </div>
      </div>
    </div>
  );
};

export default RenderGraphHeader;
