import * as React from "react";
import { Routes, Route } from "react-router";
import App from "./App";
import Login from "./components/Auth/Login";
import { LOGIN_ROUTE, LOGOUT_ROUTE } from "./routing/utils";
import Logout from "./components/Auth/Logout";
import Oauth from "./components/Auth/Oauth";

const Main = () => {
  return (
    <Routes>
      <Route path={LOGIN_ROUTE} element={<Login />} />
      <Route path={LOGOUT_ROUTE} element={<Logout />} />
      <Route path="/oauth" element={<Oauth />} />
      <Route path="*" element={<App />} />
    </Routes>
  );
};

export default Main;
