import MDEditor from "@uiw/react-md-editor";
import { Empty } from "antd";
import React from "react";
// import { Note } from ".";
import { createUseStyles } from "react-jss";
import { Note } from ".";

const style = createUseStyles({
  container: {
    height: "100%",
    display: "flex",
    paddingBottom: "1rem",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    overflow: "hidden",
  },
  editorCont: {
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  header: {
    padding: 10,
    background: "#ECECF3",
    marginBottom: "1rem",
  },
  title: {
    padding: "5px 10px",
    margin: "10px 0",
    fontSize: "0.8rem",
    fontWeight: 500,
    alignSelf: "flex-start",
    display: "flex",
    width: 150,
    justifyContent: "flex-start",
    background: "rgba(24, 144, 255, 0.1)",
    borderRadius: "5px",
    borderLeft: "6px solid #1890FF",
  },
  editor: {
    flexGrow: 1,
    width: "100%",
    overflow: "hidden",
    overflowY: "scroll",
    overflowX: "scroll",
    borderLeft: "3px solid #434343",
  },
});

const RenderNote = ({ note }: { note?: Note }) => {
  const classes = style();
  return (
    <div className={classes.container}>
      <div className={classes.header}>Resolution Notes</div>
      {note ? (
        <>
          <div className={classes.editorCont}>
            <div className={classes.title}>CAUSE</div>
            <div className={classes.editor} data-color-mode="light">
              <MDEditor.Markdown
                style={{
                  width: "100%",
                  fontSize: "0.85rem",
                  padding: 25,
                  position: "relative",
                }}
                source={note?.cause}
                linkTarget="_blank"
              />
            </div>
          </div>
          <div className={classes.editorCont}>
            <div className={classes.title}>RESOLUTION</div>
            <div className={classes.editor} data-color-mode="light">
              <MDEditor.Markdown
                style={{
                  fontSize: "0.85rem",
                  padding: 25,
                  position: "relative",
                }}
                source={note?.resolution}
                linkTarget="_blank"
              />
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Empty />
        </div>
      )}
    </div>
  );
};

export default RenderNote;
