import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0 0 20px",
    height: "100%",
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "0 20px",
    background: "#FFFFFF",
    flexGrow: 1,
    width: "100%",
    justifyContent: "center",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 8,
    width: "100%",
  },
  title: {
    fontWeight: 500,
    fontSize: "1rem",
    padding: "15px 2px ",
    display: "flex",
    alignItems: "center",
  },
  status: {
    borderRadius: 15,
    width: "5rem",
    margin: "0.8rem",
    padding: 5,
    fontSize: "0.8rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  innerCont: {
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",
    width: "100%",
  },
  tabCont: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    overflow: "hidden",
    overflowY: "scroll",
  },
  playCont: {
    border: "1px solid #D9D9D9",
    borderRadius: 3,
    width: "30%",
    marginLeft: "1rem",
    marginBottom: "1rem",
    overflow: "scroll",
  },
  descpCont: {
    padding: "0.9rem 1rem",
    borderRadius: 10,
    margin: "1rem 0",
    background: "rgba(245, 34, 45, 0.06)",
  },
  alarmTitle: {
    color: "#F5222D",
    fontSize: "0.9rem",
  },
  name: {
    fontWeight: 300,
  },
  descp: {
    fontWeight: 300,
    fontSize: "0.8rem",
  },
});

export default styles;
