import { Source } from ".";
import { GET } from "../../services/apiHandler";
import { FETCH_SOURCE } from "./constants";

export const getSourceData = async (
  incident_id?: string | null,
  event_id?: string | null
) => {
  try {
    const { isSuccess, errorMessage, data } = await GET(
      `${FETCH_SOURCE(incident_id, event_id)}`
    );
    if (!isSuccess) throw errorMessage;
    let newSourceData: Source[] = [];
    data?.source?.forEach((source: any) => {
      newSourceData = [...newSourceData, source];
    });

    return { newSourceData };
  } catch (error) {
    throw error;
  }
};
