import { notification } from "antd";
import moment from "moment";

type NotificationType = "success" | "info" | "warning" | "error";

export const getDuration = (startTime: number, endTime: number) => {
  const start = moment.unix(startTime / 1000);
  const end = endTime
    ? moment.unix(endTime / 1000)
    : moment.unix(Date.now() / 1000);
  const duration = moment
    .duration(end.diff(start), "milliseconds")
    .format("d[d] h[h] m[m]");
  return duration;
};

export const getStartTime = (startTime: number): string => {
  return moment.unix(startTime / 1000).format("MMM D, YYYY | hh:mm:ss A");
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export const getDateTimeISO = (timestamp: string): string => {
  return moment(timestamp).format("D/M/YY | HH:mm");
};
export const getDateTimeUNIX = (timestamp: number): string => {
  const t = moment.unix(timestamp / 1000).format("D/M/YY | LT");
  return t;
};

export const getISOtoUNIX = (timeStamp: string): number => {
  return moment(timeStamp).unix();
};

export const openNotification = (type: NotificationType, message: string) => {
  notification[type]({
    message: `${message}`,
    duration: 2,
  });
};
