import { UsergroupAddOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Button, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router";
import { openInNewTab } from "../../../common/utils";
import { getWarzoneData, getWarzoneLink } from "./service";

export interface User {
  key: string;
  name: string;
  email: string;
  profilePic: string;
  lastJoined: string;
  type: "Member" | "Admin";
}

const style = createUseStyles({
  spin: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    padding: "10px",
    fontWeight: 600,
    fontSize: "0.75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rowItem: {
    width: "100%",
    fontSize: "0.75rem",
    fontWeight: 300,
    height: "3.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topbar: {
    fontSize: "0.75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
  pic: {
    width: "2.25rem",
    height: "2.25rem",
    borderRadius: "50%",
  },
});

const RenderHeader = ({ header }: any) => {
  const classes = style();
  return <div className={classes.header}>{header}</div>;
};

const Warzone = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [joined, setJoined] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingLink, setLoadingLink] = useState(false);
  const classes = style();
  const { incident_id, event_id } = useParams();

  const fetchWarzoneData = async () => {
    setLoading(true);
    const { users: usersData } = await getWarzoneData(incident_id, event_id);
    setJoined(usersData.length);
    setUsers(usersData);
    setLoading(false);
  };

  useEffect(() => {
    fetchWarzoneData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incident_id, event_id]);

  const handleClickWarzone = () => {
    const fetchWazoneLink = async () => {
      setLoadingLink(true);
      const { link } = await getWarzoneLink(incident_id, event_id);
      setLoadingLink(false);
      openInNewTab(link);
      fetchWarzoneData();
    };
    fetchWazoneLink();
  };

  const columns: ColumnsType<User> = [
    {
      title: () => <RenderHeader header={"Members Joined"} />,
      key: "user",
      dataIndex: "user_",
      width: "30%",
      render: (_, { name, profilePic, email }, index) => {
        return (
          <div
            key={index}
            style={{ justifyContent: "flex-start", paddingLeft: 10 }}
            className={classes.rowItem}
          >
            <img className={classes.pic} src={`${profilePic}`} alt="Profile" />
            <div style={{ padding: "0.7rem" }}>
              <div style={{ fontSize: "0.85rem" }}>{name}</div>
              <div>{email}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => <RenderHeader header={"Type"} />,
      key: "type",
      dataIndex: "type_",
      width: "30%",
      render: (_, { type }, index) => {
        return (
          <div
            key={index}
            style={{ paddingLeft: 10 }}
            className={classes.rowItem}
          >
            {type}
          </div>
        );
      },
    },
    {
      title: () => <RenderHeader header={"Time"} />,
      key: "lastJoined",
      dataIndex: "lastJoined_",
      width: "30%",
      render: (_, { lastJoined }, index) => {
        return (
          <div
            key={index}
            style={{ paddingLeft: 10 }}
            className={classes.rowItem}
          >
            {lastJoined}
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {!loading ? (
        <>
          <div className={classes.topbar}>
            <div className={classes}>{joined} Members Joined Warzone</div>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  marginLeft: "1rem",
                  color: "#1890FF",
                  borderColor: "#1890FF",
                }}
                icon={
                  <UsergroupAddOutlined
                    style={{ fontSize: "1rem", transform: "scaleX(-1)" }}
                  />
                }
              >
                Invite
              </Button>
              <Spin spinning={loadingLink} size={"small"}>
                <Button
                  onClick={handleClickWarzone}
                  type="primary"
                  style={{ marginLeft: "1rem" }}
                  icon={
                    <VideoCameraOutlined
                      style={{ fontSize: "0.9rem", transform: "scaleX(-1)" }}
                    />
                  }
                >
                  Join War-Zone
                </Button>
              </Spin>
            </div>
          </div>
          <Table
            pagination={false}
            scroll={{ y: "calc(100vh - 31rem)" }}
            columns={columns}
            dataSource={users}
            rowKey={"Id"}
          />
        </>
      ) : (
        <Spin className={classes.spin} />
      )}
    </div>
  );
};

export default Warzone;
