import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "40px",
    background: "#FFFFFF",
    minWidth: 200,
  },
  head: {
    padding: "10px 0px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#262626",
    margin: "2px 0",
    minWidth: "150px",
    paddingLeft: (props: any) => props.indent,
  },
  link: {
    extend: "head",
    paddingLeft: (props: any) => props.indent,
    background: ({ isActive }: any) => (isActive ? "#E8F4FF" : null),
    borderRight: ({ isActive }: any) =>
      isActive ? "3px solid #1890FF" : "3px solid #E2E2E2",
    "&:hover": {
      color: "#1890FF",
    },
    color: ({ isActive }: any) => (isActive ? "#1890FF" : "#262626"),
  },
});

export default styles;
