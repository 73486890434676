import {
  ProjectOutlined,
  VideoCameraOutlined,
  WarningFilled,
} from "@ant-design/icons";
import { Button, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ViewList, { User } from "../../../ViewList";
import Playbook from "./Playbook";
import RenderNotes from "./RenderNotes";
import { getEventData, getEventViews } from "./service";
import styles from "./styles";
import { data } from "./utils";
import Warzone from "./Warzone";

export interface Note {
  cause?: string;
  resolution?: string;
}
export interface EventData {
  id: string;
  start: string;
  status: "OPEN" | "CLOSED";
  views: User[];
  name: string;
  since: string;
  description: string;
  notes?: Note;
  opsGenieId?: string;
}

function Overview() {
  const [event, setEvent] = useState<EventData | null>(data);
  const [loading, setLoading] = useState(true);

  const { TabPane } = Tabs;
  const classes = styles();
  const navigate = useNavigate();
  const { incident_id, event_id } = useParams();

  useEffect(() => {
    const fetchEventViews = async () => {
      const { users } = await getEventViews(incident_id, event_id);

      setEvent((previousEvent) => {
        let newEvent = Object.assign({}, previousEvent);
        newEvent.views = users;
        return newEvent;
      });
    };
    fetchEventViews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const { eventData } = await getEventData(incident_id, event_id);        setEvent((previousEvent) => {
          let newEvent = Object.assign({}, previousEvent);
          newEvent.id = eventData.id;
          newEvent.name = eventData.name;
          newEvent.description = eventData.description;
          newEvent.since = eventData.since;
          newEvent.start = eventData.start;
          newEvent.status = eventData.status;
          newEvent.notes = eventData.notes;
          newEvent.opsGenieId = eventData.opsGenieId;
          return newEvent;
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("ERROR ", error);
      }
    };
    fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.mainContainer}>
      <div></div>
      <div className={classes.container}>
        {!loading ? (
          <>
            {" "}
            <div className={classes.head}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.title}>{event?.start}</div>
                <div
                  className={classes.status}
                  style={{
                    background:
                      event?.status === "OPEN"
                        ? "rgba(245, 34, 45, 0.1)"
                        : "rgba(82, 196, 26, 0.1)",
                    color: event?.status === "OPEN" ? "#F5222D" : "#52C41A",
                  }}
                >
                  {event?.status === "OPEN" ? "Open" : "Closed"}
                </div>
              </div>
              <div>
                <Button
                  type="primary"
                  style={{ marginLeft: "1rem" }}
                  icon={
                    <VideoCameraOutlined
                      style={{ fontSize: "0.9rem", transform: "scaleX(-1)" }}
                    />
                  }
                >
                  Join War-Zone
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: "1rem" }}
                  icon={
                    <ProjectOutlined
                      style={{ fontSize: "0.9rem", transform: "scaleX(-1)" }}
                    />
                  }
                  onClick={() =>
                    navigate(`/incidents/${incident_id}/0/overview`)
                  }
                >
                  Recent Event
                </Button>
              </div>
            </div>
            <div className={classes.innerCont}>
              <div className={classes.tabCont}>
                <div className={classes.tabHead}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 5px",
                      fontSize: "0.8rem",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span style={{ fontWeight: 300 }}>Open Since/TTR:</span>{" "}
                      {event?.since}
                    </div>
                    <ViewList
                      metadataLoading={false}
                      width={200}
                      views={event?.views || []}
                    />
                  </div>
                  <div className={classes.descpCont}>
                    <div className={classes.alarmTitle}>
                      <WarningFilled
                        style={{ paddingRight: "8px", fontSize: "0.8rem" }}
                      />
                      #{event?.opsGenieId || ""} Alarm: <span className={classes.name}>{event?.name}</span>
                    </div>
                    <div className={classes.descp}>{event?.description}</div>
                  </div>
                </div>
                <Tabs
                  centered
                  style={{ flexGrow: 1 }}
                  onChange={() => {}}
                  type="card"
                >
                  <TabPane
                    style={{ flexGrow: 1 }}
                    tab="Recommendation and Insights"
                    key="1"
                  >
                    <RenderNotes note={event?.notes} />
                  </TabPane>
                  <TabPane
                    style={{ flexGrow: 1 }}
                    tab="Action Requests"
                    key="2"
                  >
                    Action Requests
                  </TabPane>
                  <TabPane style={{ flexGrow: 1 }} tab="Join War Zone" key="3">
                    <Warzone />
                  </TabPane>
                </Tabs>
              </div>
              <div className={classes.playCont}>
                <Playbook />
              </div>
            </div>
          </>
        ) : (
          <Spin size="large" />
        )}
      </div>
    </div>
  );
}

export default Overview;
