import * as React from "react";
import { Navigate, Route, Routes } from "react-router";
import Navbar from "../components/Navbar";
import Incidents from "../components/pages/Incidents";
import Insights from "../components/pages/Insights";
import MTTR from "../components/pages/MTTR";

const RouterOutlet = (props: any) => {
  return (
    <Routes>
      <Route path="/" element={<Navbar />}>
        <Route path="incidents/*" element={<Incidents />} />
        <Route path="insights" element={<Insights />} />
        <Route path="mttr" element={<MTTR />} />
        <Route path="/" element={<Navigate replace to="/incidents" />} />
      </Route>
      <Route path="/invalid" element={<h1>Invalid Route : Try Again</h1>} />
      <Route path="*" element={<Navigate to="/invalid" />} />
    </Routes>
  );
};

export default RouterOutlet;
