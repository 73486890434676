import React, { useCallback, useEffect, useState } from "react";
import { Chart as ChartJS, registerables, ChartDataset } from "chart.js";
import { Chart } from "react-chartjs-2";
import { InsightData, Query } from ".";
import { chartColor } from "./utils";
import { getDateTimeUNIX } from "../../../common/utils";
import moment from "moment";
import RenderSQL from "./RenderSQL";
import { Divider } from "antd";
ChartJS.register(...registerables);

const RenderInsights = ({
  insightData,
  SQLData,
}: {
  insightData: InsightData;
  SQLData: Query[];
}) => {
  const [datasets, setDatasets] = useState<ChartDataset<"bar", number[]>[]>([]);
  const [labels, setLabels] = useState<unknown[] | undefined>([]);

  const resetData = useCallback(() => {
    setLabels(insightData.labels);
    const newDatasets: ChartDataset<"bar", number[]>[] = insightData.data.map(
      (val, index) => {
        return {
          data: val.values,
          borderColor: `#0${index}bbff`,
          backgroundColor: chartColor.get(val.name),
          label: val.name,
          minbarThickness: 12,
          maxBarThickness: 16,
          minBarLength: 3,
        };
      }
    );
    setDatasets(newDatasets);
  }, [insightData.data, insightData.labels]);

  useEffect(() => {
    resetData();
  }, [resetData]);

  return (
    <div>
      <div
        style={{
          fontSize: "1.3rem",
          fontWeight: 500,
          padding: "1rem 0.6rem",
        }}
      >
        Performance Insights
      </div>
      <div
        style={{
          padding: "1.2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "1.2rem",
          boxShadow: "0px 0px 10px 3px rgb(230 230 230 / 69%)",
        }}
      >
        <div
          style={{
            fontSize: "1rem",
            padding: "0.6rem",
            fontWeight: 500,
          }}
        >
          Average Active Sessions (AAS){" "}
        </div>
        <div style={{ width: "60%" }}>
          <RenderChart datasets={datasets} labels={labels} />
        </div>
      </div>
      <Divider />
      <div
        style={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "1.5rem 1.2rem",
          boxShadow: "0px 0px 10px 3px rgb(230 230 230 / 69%)",
        }}
      >
        <div
          style={{
            alignSelf: "flex-start",
            fontSize: "1rem",
            padding: "0.6rem",
            fontWeight: 500,
          }}
        >
          Top SQL <span style={{ fontWeight: "lighter" }}> (11)</span>
        </div>
        <RenderSQL
          resetData={resetData}
          setDatasets={setDatasets}
          setLabels={setLabels}
          SQLData={SQLData}
        />
      </div>
    </div>
  );
};

export default RenderInsights;

const RenderChart = ({
  datasets,
  labels,
}: {
  datasets: ChartDataset<"bar", number[]>[];
  labels: unknown[] | undefined;
}) => {
  return (
    <Chart
      type="bar"
      data={{
        datasets: datasets,
        labels: labels,
      }}
      options={{
        animation: false,
        responsive: true,
        scales: {
          x: {
            stacked: true,
            ticks: {
              callback(_, index) {
                return index !== 0
                  ? moment
                      .unix(parseInt(this.getLabelForValue(index)))
                      .format("LT")
                  : "";
              },
              maxRotation: 0,
              minRotation: 0,
            },
          },
          y: {
            stacked: true,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (ctx) => {
                return getDateTimeUNIX(parseInt(ctx[0].label) * 1000);
              },
            },
          },
          legend: {
            position: "right",
            labels: {
              boxPadding: 30,
            },
          },
          title: {
            display: true,
            text: "Average Active Sessions (AAS)",
            position: "top",
          },
        },
      }}
    />
  );
};
