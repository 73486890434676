import React, { useState } from "react";
import { Note } from ".";
import { createUseStyles } from "react-jss";
import { Button } from "antd";
import { getEventsNotesData } from "./services";

const styles = createUseStyles({
  container: {
    width: "90%",
    padding: "8px 0",
    cursor: "pointer",
  },
  view: {
    padding: "0px 6px",
    borderRadius: 5,
    fontWeight: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "55px",
    margin: "0 auto",
    background: "#FFFFF",
    color: "#1890FF",
    fontSize: "0.75rem",
    border: "1.5px solid #1890FF",
    height: "25px",
  },
  add: {
    height: "25px",
    padding: "0px 6px",
    borderRadius: 5,
    fontWeight: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "55px",
    margin: "0 auto",
    background: "#1890FF",
    color: "#FFFF",
    fontSize: "0.75rem",
    border: "1.5px solid #1890FF",
    cursor: "pointer",
  },
});

function RenderNoteButton({
  notes,
  setIsView,
  setNote,
  setVisible,
  setNoteIndex,
  index,
  incidentId,
  eventId,
  setEventId,
}: {
  notes?: Note;
  eventId: string;
  incidentId: string;
  setIsView: any;
  setVisible: any;
  setNote: any;
  setNoteIndex: any;
  index: any;
  setEventId: any;
}) {
  const [loading, setLoading] = useState(false);
  const classes = styles({ notes: notes });

  const handleClick = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (loading) return;
    setEventId(eventId);
    setNoteIndex(index);
    if (notes && incidentId && eventId) {
      setLoading(true);
      const { note } = await getEventsNotesData({
        incident_id: incidentId,
        event_id: eventId,
      });
      setNote(note);
      setLoading(false);
      setIsView(true);
      setVisible(true);
    } else {
      setNote(null);
      setIsView(false);
      setVisible(true);
    }
  };

  return (
    <div className={classes.container} onClick={(e) => handleClick(e)}>
      {notes ? (
        <Button disabled={loading} className={classes.view}>
          View
        </Button>
      ) : (
        <Button disabled={loading} className={classes.add}>
          + Add
        </Button>
      )}
    </div>
  );
}

export default RenderNoteButton;
