import { CopyOutlined } from "@ant-design/icons";
import { Button, Radio, RadioChangeEvent } from "antd";
import React, { useState } from "react";
import { Chart as ChartJS, registerables, ChartDataset } from "chart.js";
import { Query } from ".";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { openNotification } from "../../../common/utils";
import { chartColor } from "./utils";
import { createUseStyles } from "react-jss";

ChartJS.register(...registerables);

const styles = createUseStyles({
  tableCont: { margin: "1rem 0", border: "1px solid rgba(0,0,0,.06)" },
  tableContent: { height: "20rem", width: "67rem", overflow: "scroll" },
  textCont: {
    margin: "1rem 0",
    width: "67rem",
    border: "1px solid rgba(0,0,0,.06)",
  },
  textHeader: {
    fontWeight: "bold",
    display: "inline-block",
    padding: "1rem",
    color: "orangered",
    borderBottom: "2px solid black",
  },
  contentWrapper: {
    background: "whitesmoke",
    padding: "1rem",
    margin: "1rem",
    height: "20rem",
    overflowY: "scroll",
  },
  content: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    marginBottom: 0,
  },
});

const RenderHeader = ({
  header,
  resetData,
  setSQLtext,
}: {
  header: string;
  resetData: any;
  setSQLtext: any;
}) => {
  return (
    <div
      style={{
        padding: "1rem",
        width: "67rem",
        fontWeight: 600,
        color: "grey",
        background: "whitesmoke",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>{header}</div>
      <Button
        onClick={() => {
          resetData();
          setSQLtext("");
        }}
      >
        Reset
      </Button>
    </div>
  );
};
const RenderItem = ({ sql }: { sql: Query }) => {
  return (
    <div
      style={{
        width: "100%",
        padding: "1rem",
        border: "1px solid rgba(0,0,0,.06)",
        display: "flex",
      }}
    >
      <Radio style={{ marginRight: "1rem", width: "100%" }} value={sql}>
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            // width: "60rem",
          }}
        >
          {sql.statement}
        </div>
      </Radio>
    </div>
  );
};

const RenderSQL = ({
  SQLData,
  setDatasets,
  setLabels,
  resetData,
}: {
  SQLData: Query[];
  resetData: any;
  setDatasets: React.Dispatch<
    React.SetStateAction<ChartDataset<"bar", number[]>[]>
  >;
  setLabels: React.Dispatch<React.SetStateAction<unknown[] | undefined>>;
}) => {
  const [SQLtext, setSQLtext] = useState("");

  const classes = styles();
  const handleChange = (e: RadioChangeEvent) => {
    const sql = e.target.value;
    setDatasets([
      ...[
        {
          data: sql.values,
          borderColor: `#0${0}bbff`,
          backgroundColor: chartColor.get("tuple"),
          label: "sql",
          barThickness: 12,
          maxBarThickness: 16,
          minBarLength: 3,
        },
      ],
    ]);
    setLabels(sql.labels);

    setSQLtext(sql.statement);
  };

  return (
    <div>
      <div className={classes.tableCont}>
        <RenderHeader
          setSQLtext={setSQLtext}
          resetData={resetData}
          header={"SQL Statements"}
        />
        <Radio.Group
          value={SQLtext}
          onChange={handleChange}
          optionType="button"
          buttonStyle="solid"
        >
          <div className={classes.tableContent}>
            {SQLData.map((sql) => {
              return <RenderItem key={sql.key} sql={sql} />;
            })}
          </div>
        </Radio.Group>
      </div>
      <div className={classes.textCont}>
        <div
          style={{
            border: "1px solid rgba(0,0,0,.06)",
          }}
        >
          <div className={classes.textHeader}>SQL text</div>
        </div>
        <div className={classes.contentWrapper}>
          <pre className={classes.content}>{SQLtext}</pre>
        </div>
        {SQLtext !== "" ? (
          <CopyToClipboard
            text={SQLtext}
            onCopy={() =>
              openNotification("success", "SQL statement copied to clipboard")
            }
          >
            <Button
              style={{
                margin: "0 1rem 1rem 1rem",
              }}
              icon={<CopyOutlined />}
            >
              Copy
            </Button>
          </CopyToClipboard>
        ) : null}
      </div>
    </div>
  );
};

export default RenderSQL;
