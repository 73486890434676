import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles";
import ViewList, { User } from "../../../ViewList";
import { Button, Drawer, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import RenderNoteButton from "./RenderNoteButton";
import RenderStatus from "./RenderStatus";
import { EditOutlined, WarningOutlined } from "@ant-design/icons";
import EditNote from "./EditNote";
import RenderNote from "./RenderNote";
import { getEventsData, getEventsViewsData } from "./services";
import { useNavigate } from "react-router";
import { useParams } from "react-router";

export interface Event {
  key: number;
  name: string;
  startTime: string;
  status: "OPEN" | "CLOSED";
  since: string;
  view: User[];
  notes?: Note;
}

export interface Incident {
  name: string;
  events: Event[];
}

export interface Note {
  cause?: string;
  resolution?: string;
}

const RenderHeader = ({ header }: any) => {
  const classes = styles();
  return <div className={classes.header}>{header}</div>;
};

const Events = () => {
  const [incidentName, setIncidentName] = useState<string>("");
  const [events, setEvents] = useState<Event[]>([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eventIdList, setEventIdList] = useState<string[]>([]);
  const [eventId, setEventId] = useState();
  const [note, setNote] = useState<Note | null>(null);
  const [isView, setIsView] = useState(false);
  const [noteIndex, setNoteIndex] = useState<number | null>(null);

  const classes = styles();
  const navigate = useNavigate();
  const { incident_id } = useParams();

  const renderUpdate = () => {
    return (
      <div className={classes.updateCont}>
        <Button
          onClick={() => setIsView(false)}
          className={classes.update}
          type="primary"
          icon={<EditOutlined />}
        >
          Update
        </Button>
      </div>
    );
  };

  const fetchEvents = useCallback(async () => {
    try {
      setLoading(true);
      const { eventData, idList } = await getEventsData(incident_id);
      setIncidentName(eventData[0]?.name);
      setEvents(eventData);
      setEventIdList(idList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }, [incident_id]);

  useEffect(() => {
    incident_id && fetchEvents();
  }, [fetchEvents, incident_id]);

  useEffect(() => {
    const fetchEventsViews = async () => {
      const { viewData } = await getEventsViewsData({
        incident_id,
        eventIdList,
      });
      const newEvents = events;
      newEvents.forEach((event, index) => {
        event.view = viewData[index];
      });
      setEvents([...newEvents]);
    };

    eventIdList.length && fetchEventsViews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventIdList, incident_id]);

  const onClose = () => {
    setVisible(false);
  };

  const columns: ColumnsType<Event> = [
    {
      title: () => <RenderHeader header={"Events"} />,
      key: "startTime",
      dataIndex: "startTime",

      width: "30%",
      render: (_, { startTime }, index) => {
        return (
          <div
            key={index}
            style={{ justifyContent: "flex-start", paddingLeft: 10 }}
            className={classes.rowItem}
          >
            {startTime}
          </div>
        );
      },
    },
    {
      title: () => <RenderHeader header={"Status"} />,
      key: "status",
      dataIndex: "status_",
      width: "10%",
      render: (_, { status }, index) => {
        return (
          <div key={index} className={classes.rowItem}>
            <RenderStatus status={status} />
          </div>
        );
      },
    },
    {
      title: () => <RenderHeader header={"Open Since/TTR"} />,
      key: "since",
      dataIndex: "since_",
      width: "12%",
      render: (_, { since }, index) => {
        return (
          <div key={index} className={classes.rowItem}>
            {since}
          </div>
        );
      },
    },
    {
      title: () => <RenderHeader header={"Members Viewed"} />,
      key: "view",
      dataIndex: "view_",
      width: "20%",
      render: (_, { view }, index) => {
        return (
          <div key={index} className={classes.rowItem}>
            <ViewList metadataLoading={false} views={view} width={190} />
          </div>
        );
      },
    },
    {
      title: () => <RenderHeader header={"Resolution Notes"} />,
      key: "note",
      dataIndex: "note",
      width: "10%",
      render: (_, { notes, key }, index) => {
        return (
          <div key={index} className={classes.rowItem}>
            <RenderNoteButton
              setNote={setNote}
              setIsView={setIsView}
              incidentId={incident_id || ""}
              eventId={key.toString() || ""}
              notes={notes}
              setVisible={setVisible}
              setNoteIndex={setNoteIndex}
              index={index}
              setEventId={setEventId}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className={classes.mainContainer}>
      <div></div>
      <div className={classes.container}>
        {!loading ? (
          <>
            <div className={classes.head}>
              <div className={classes.title}>
                <WarningOutlined
                  style={{ padding: "0 8px", fontSize: "1.2rem" }}
                />
                Alarm: <span className={classes.name}>{incidentName}</span>
              </div>
            </div>
            <div className={classes.tableContainer}>
              <Table
                pagination={false}
                scroll={{ y: "calc(100vh - 15em)" }}
                columns={columns}
                dataSource={events}
                onRow={({ key }) => {
                  return {
                    onClick: () => {
                      navigate(`../${key}/overview`, { replace: false });
                    },
                  };
                }}
              />
            </div>
            <Drawer
              title={`${isView ? "" : "Add"} Resolution Notes`}
              placement="right"
              onClose={onClose}
              visible={visible}
              width={"100vw"}
              extra={<>{isView ? renderUpdate() : ""}</>}
            >
              {!isView ? (
                <EditNote
                  noteIndex={noteIndex}
                  setNote={setNote}
                  note={note}
                  events={events}
                  eventId={eventId}
                  incidentId={incident_id}
                  setEvents={setEvents}
                  setVisible={setVisible}
                />
              ) : (
                <RenderNote setView={setIsView} note={note} />
              )}
            </Drawer>
          </>
        ) : (
          <Spin size="large" />
        )}
      </div>
    </div>
  );
};

export default Events;
