import { User } from ".";

export const users: User[] = [
  {
    name: "Dr. Marion Yundt",
    email: "Valentine.Tillman16@hotmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/441.jpg",
  },
  {
    name: "Sherri Harris MD",
    email: "Jensen45@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/834.jpg",
  },
  {
    name: "Ervin Terry",
    email: "Ervin.Cremin@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1131.jpg",
  },
  {
    name: "Terrence Smitham",
    email: "Keyshawn_Jakubowski@gmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/249.jpg",
  },
  {
    name: "Janice Sauer",
    email: "Geovany_Kling@hotmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1055.jpg",
  },
  {
    name: "Ms. Cory Kovacek",
    email: "Lowell.Dare36@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1108.jpg",
  },
  {
    name: "Ervin Terry",
    email: "Ervin.Cremin@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1131.jpg",
  },
  {
    name: "Terrence Smitham",
    email: "Keyshawn_Jakubowski@gmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/249.jpg",
  },
  {
    name: "Janice Sauer",
    email: "Geovany_Kling@hotmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1055.jpg",
  },
  {
    name: "Ms. Cory Kovacek",
    email: "Lowell.Dare36@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1108.jpg",
  },
  {
    name: "Ervin Terry",
    email: "Ervin.Cremin@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1131.jpg",
  },
  {
    name: "Terrence Smitham",
    email: "Keyshawn_Jakubowski@gmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/249.jpg",
  },
  {
    name: "Janice Sauer",
    email: "Geovany_Kling@hotmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1055.jpg",
  },
  {
    name: "Ms. Cory Kovacek",
    email: "Lowell.Dare36@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1108.jpg",
  },
  {
    name: "Ervin Terry",
    email: "Ervin.Cremin@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1131.jpg",
  },
  {
    name: "Terrence Smitham",
    email: "Keyshawn_Jakubowski@gmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/249.jpg",
  },
  {
    name: "Janice Sauer",
    email: "Geovany_Kling@hotmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1055.jpg",
  },
  {
    name: "Ms. Cory Kovacek",
    email: "Lowell.Dare36@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1108.jpg",
  },
  {
    name: "Ervin Terry",
    email: "Ervin.Cremin@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1131.jpg",
  },
  {
    name: "Terrence Smitham",
    email: "Keyshawn_Jakubowski@gmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/249.jpg",
  },
  {
    name: "Janice Sauer",
    email: "Geovany_Kling@hotmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1055.jpg",
  },
  {
    name: "Ms. Cory Kovacek",
    email: "Lowell.Dare36@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1108.jpg",
  },
  {
    name: "Ervin Terry",
    email: "Ervin.Cremin@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1131.jpg",
  },
  {
    name: "Terrence Smitham",
    email: "Keyshawn_Jakubowski@gmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/249.jpg",
  },
  {
    name: "Janice Sauer",
    email: "Geovany_Kling@hotmail.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1055.jpg",
  },
  {
    name: "Ms. Cory Kovacek",
    email: "Lowell.Dare36@yahoo.com",
    picURL:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1108.jpg",
  },
];
