import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducer";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    console.error(error);
  },
});

const configStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([thunk, sagaMiddleware]),
  });

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducer", () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

export default configStore;
