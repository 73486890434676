import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0 0 20px",
    height: "100%",
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    background: "#FFFFFF",
    flexGrow: 1,
    justifyContent: "center",
  },
  innerCont: {
    display: "flex",
    padding: "0 20px",
    flexDirection: "column",
    marginBottom: "1rem",
    overflow: "hidden",
    overflowY: "scroll",
  },
  title: {
    // fontWeight: 600,
    fontSize: "1.2rem",
    padding: "0.3rem",
    paddingLeft: "0.7rem",
    marginTop: "2rem",
    margin: "0.7rem 0",
    display: "flex",
    alignItems: "center",
  },
  name: {
    fontSize: "0.95rem",
    fontWeight: 300,
    marginLeft: 10,
  },
  data: {
    flexGrow: 1,
    overflow: "hidden",
    overflowY: "scroll",
    paddingBottom: "1.5rem",
  },
  chartCont: {
    display: "flex",
    flexWrap: "wrap",
  },
  head: {
    fontWeight: 600,
    paddingBottom: "1rem",
  },
});

export default styles;
