const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector(".tooltip-container");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.classList.add("tooltip-container");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.zIndex = 2;
    tooltipEl.style.width = "135px";
    tooltipEl.style.fontSize = "0.7rem";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0) translateY(100%)";

    // const container = document.createElement("div");
    tooltipEl.style.display = "flex";
    tooltipEl.style.flexDirection = "column";
    tooltipEl.style.alignItems = "center";
    tooltipEl.style.justifyContent = "center";

    // tooltipEl.appendChild(container);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
};

export const externalTooltipHandler = (context: any) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b: any) => b.lines);
    const dateDiv = document.createElement("div");
    dateDiv.innerText = titleLines[0];
    const countDiv = document.createElement("div");
    countDiv.innerText = bodyLines[0];

    // const container = tooltipEl.querySelector(".tooltip-container");

    while (tooltipEl?.firstChild) {
      tooltipEl.firstChild.remove();
    }

    tooltipEl?.appendChild(dateDiv);
    tooltipEl?.appendChild(countDiv);
  }

  const { offsetLeft: positionX } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.bottom = "0rem";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
};
