import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0 0 20px",
    height: "100%",
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "0 20px",
    background: "#FFFFFF",
    flexGrow: 1,
    overflowY: "scroll",
  },
  header: {
    marginBottom: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  innerCont: {
    display: "flex",
    flexWrap: "wrap",
  },
  title: {
    fontWeight: 500,
    fontSize: "0.8rem",
    padding: "0.4rem 0.8rem",
    background: "rgba(82, 196, 26, 0.1)",
    border: "1px solid rgb(82, 196, 26)",
    borderRadius: "2.2rem",
    marginLeft: "0.6rem",
    marginTop: "0.6rem",
  },
  head: {
    fontWeight: 600,
    fontSize: "1.3rem",
    padding: "1.3rem 0.6rem",
    paddingBottom: 0,
  },
  chartCont: {
    width: "31%",
    padding: "1.2rem",
    margin: "1%",
    boxShadow: "0px 0px 10px 3px rgba(230,230,230,0.69)",
    borderRadius: 4,
  },
  chartHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.4rem",
  },
});

export default styles;
