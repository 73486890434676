export let lowerCaseToUpperCase = new Map();

lowerCaseToUpperCase.set("readiops", "Read IOPS");
lowerCaseToUpperCase.set("writeiops", "Write IOPS");
lowerCaseToUpperCase.set("freestoragespace", "Free Storage Space");
lowerCaseToUpperCase.set("databaseconnections", "DB Connections");
lowerCaseToUpperCase.set("cpuutilization", "CPU Utilization");
lowerCaseToUpperCase.set("freeablememory", "Freeable Memory");

export let chartColor = new Map();

chartColor.set("CPU", "#67db60");
chartColor.set("tuple", "#db8132");
chartColor.set("ClientRead", "#f2c655");
chartColor.set("ClientWrite", "#c43631");
chartColor.set("DataFileRead", "#c69ae3");
chartColor.set("WALWriteLock", "#79b2fc");
chartColor.set("transactionid", "#737578");
chartColor.set("MessageQueueSend", "#d8db81");
