const styles = (theme: any) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  container: {
    fontFamily: "sans-serif",
    background: "#FFFFFF",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.12)",
    display: "flex",
    zIndex: 1,
  },
  left: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tab: {
    fontWeight: 300,
    fontSize: "0.9rem",
    padding: "12px 25px",
    verticalAlign: "middle",
    borderBottom: "3px solid #FFFFFF",
    color: "#262626",
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
  },
  brand: {
    fontWeight: 700,
    fontSize: "1.3rem",
    display: "flex",
    justifyContent: "center",
    color: "#262626",
    alignItems: "center",
    padding: " 0 0 0 20px",
    paddingRight: "8%",
  },
  tabs: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  selected: {
    background: "rgba(24, 144, 255, 0.1)",
    borderBottom: "3px solid #1890FF",
  },
});

export default styles;
