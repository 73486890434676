import { Divider, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import RenderChart from "./RenderChart";
import RenderInsights from "./RenderInsights";
import { getRDSData } from "./service";
import styles from "./styles";

export interface ChartData {
  title: string;
  unit: string;
  timestamps: string[];
  values: number[];
  startTime?: number;
}

export interface InsightData {
  labels: number[];
  data: {
    name: string;
    values: number[];
  }[];
}

export interface Query {
  key: number;
  statement: string;
  values: number[];
  labels: number[];
}

const RDS = () => {
  const [monitoringData, setMonitoringData] = useState<ChartData[]>([]);
  const [insightData, setInsightData] = useState<InsightData>({
    data: [],
    labels: [],
  });
  const [sqlData, setSql] = useState<Query[]>();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [database, setDatabase] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = styles();
  const { incident_id, event_id } = useParams();

  useEffect(() => {
    const fetchRDSdata = async () => {
      try {
        setLoading(true);
        const {
          queries,
          insights,
          database,
          newMonitoringData,
          newEndTime,
          newStartTime,
        } = await getRDSData(incident_id, event_id);

        setSql(queries);
        setInsightData(insights);
        setMonitoringData(newMonitoringData);
        setStartTime(newStartTime);
        setEndTime(newEndTime);
        setDatabase(database);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchRDSdata();
  }, [event_id, incident_id]);

  return (
    <div className={classes.mainContainer}>
      <div></div>
      <div className={classes.container}>
        {!loading ? (
          <>
            <div className={classes.header}>
              <div className={classes.head}>Database: {database}</div>
              <div className={classes.title}>
                {`${startTime}  -  ${endTime}`}
              </div>
            </div>
            <div className={classes.innerCont}>
              {monitoringData.map((data, index) => {
                return (
                  <RenderChart
                    key={index}
                    chartdata={{ ...data, startTime: parseInt(startTime) }}
                  />
                );
              })}
            </div>
            <Divider />
            <RenderInsights SQLData={sqlData || []} insightData={insightData} />
          </>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
    </div>
  );
};

export default RDS;
