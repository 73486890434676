import { Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles";
import ViewList from "../../../ViewList";
import IncidentGraph from "./IncidentGraph";
import RenderGraphHeader from "./RenderGraphHeader";
import RenderIncident from "./RenderIncident";
import RenderJoin from "./RenderJoin";
import "moment-duration-format";
import { getGraphData, getIncidentsData, getMetaData } from "./services";
import { useNavigate } from "react-router";

interface User {
  name: string;
  email: string;
  picURL: string;
}

interface graph {
  label?: string[];
  count?: number[];
}

export interface IncidentType {
  id?: number;
  alarm?: string;
  name?: string;
  message?: string;
  priority?: string;
  routed_teams?: string[];
  start?: string;
}

export interface DataType {
  key: number;
  incident?: IncidentType;
  status?: "OPEN" | "CLOSED";
  count?: number;
  since?: string;
  mttr?: string;
  link?: string;
  views: User[];
  graph?: graph;
}

const RenderHeader = ({ header }: any) => {
  const classes = styles();
  return <div className={classes.header}>{header}</div>;
};

const ListIncidents = ({
  incident_status,
}: {
  incident_status?: "OPEN" | "CLOSED";
}) => {
  const classes = styles();
  const [isDay, setIsDay] = useState(true);
  const [rows, setRows] = useState<DataType[]>([]);
  const [filteredRows, setFilteredRows] = useState<DataType[]>([]);
  const [hasLazyLoadeed, sethasLazyLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);
  const [metadataLoading, setMetadataLoading] = useState(false);
  const [idList, setIdList] = useState<string[]>([]);
  const [eventIdList, setEventIdList] = useState<string[]>([]);

  const navigate = useNavigate();
  const filterIncidents = useCallback(
    (rowData: DataType[]) => {
      if (incident_status) {
        rowData = rowData.filter((row) => row.status === incident_status);
      }
      return rowData;
    },
    [incident_status]
  );

  useEffect(() => {
    if (rows.length) {
      const rowData = rows;
      !hasLazyLoadeed && lazyLoad(rowData);
      sethasLazyLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, hasLazyLoadeed]);

  useEffect(() => {
    // fetch all the incidents when component mounts and stores in rows.
    fetchIncidents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // updating filteredRows when incidents_status/rows changes
    rows.length && setFilteredRows(filterIncidents(rows));
  }, [incident_status, rows, filterIncidents]);

  useEffect(() => {
    rows.length && asyncFetchGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDay, rows]);

  const fetchIncidents = async () => {
    try {
      setLoading(true);
      const { rowData, idList, eventIdList } = await getIncidentsData();
      setIdList(idList);
      setEventIdList(eventIdList);
      setRows(rowData);
      setFilteredRows(filterIncidents(rowData));
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const asyncFetchGraph = async () => {
    const rowData = rows;
    setGraphLoading(true);
    await fetchGraph(rowData);
    setRows(rowData);
    setGraphLoading(false);
  };

  // lazy load graph and metadata
  const lazyLoad = async (rowData: DataType[]) => {
    try {
      setGraphLoading(true);
      setMetadataLoading(true);
      await Promise.all([fetchMetadata(rowData), fetchGraph(rowData)]);
      setRows(rowData);
      setMetadataLoading(false);
      setGraphLoading(false);
    } catch (error) {
      setRows(rowData);
      setMetadataLoading(false);
      setGraphLoading(false);
    }
  };

  // fetch metadata
  const fetchMetadata = async (rows: DataType[]) => {
    try {
      await getMetaData(eventIdList, rows);
      // rows = rowData;
    } catch (err) {
      throw err;
    }
  };

  // fetching graphData when isDay/rows changes;
  const fetchGraph = async (rows: DataType[]) => {
    try {
      await getGraphData(isDay, idList, rows);
    } catch (err) {
      throw err;
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: () => <RenderHeader header={"Incident Name"} />,
      dataIndex: "incident_name",
      key: "incident",
      width: "25%",
      render: (_, { incident }) => {
        return (
        <div style={{ cursor: "pointer"}}>
          <RenderIncident {...incident} />
        </div>
        );
      },
    },
    {
      title: () => (
        <RenderGraphHeader isDay={isDay} setIsDay={setIsDay} header={"Graph"} />
      ),
      dataIndex: "incident_name",
      key: "incident",
      width: "15%",
      render: (_, { graph }) => {
        return (
          <div style={{ cursor: "pointer"}}>
            <IncidentGraph
              graphLoading={graphLoading}
              count={graph?.count}
              label={graph?.label}
              />
          </div>
        );
      },
    },
    {
      title: () => <RenderHeader header={"Status"} />,
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <div style={{ cursor: "pointer"}}>
          <div className={classes.rowItem}>
            <div
              className={classes.status}
              style={{
                background:
                status === "OPEN"
                ? "rgba(245, 34, 45, 0.1)"
                : "rgba(82, 196, 26, 0.1)",
                color: status === "OPEN" ? "#F5222D" : "#52C41A",
              }}
              >
              {status === "OPEN" ? "Open" : "Closed"}
            </div>
          </div>
        </div>
      ),
      align: "center",
    },
    {
      title: () => <RenderHeader header={"Total Counts"} />,
      dataIndex: "count",
      render: (_, { count }) => (
      <div style={{ cursor: "pointer"}}>
        <div className={classes.rowItem}>{count}</div>
      </div>
      ),
      key: "count",
      align: "center",
    },
    {
      title: () => <RenderHeader header={"Open Since/TTR"} />,
      dataIndex: "since",
      align: "center",
      key: "since",
      render: (_, { since }) => (
        <div style={{ cursor: "pointer"}}>
          <div className={classes.rowItem}>{since}</div>
        </div>
      ),
    },
    {
      title: () => <RenderHeader header={"MTTR"} />,
      dataIndex: "mttr",
      key: "mttr",
      align: "center",
      render: (_, { mttr }) => (
        <div style={{ cursor: "pointer"}}>
          <div className={classes.rowItem}>{mttr || "--"}</div>
        </div>
      ),
    },
    {
      title: () => <RenderHeader header={"Viewed"} />,
      dataIndex: "link",
      key: "link",
      align: "center",
      render: (_, { views }) => (
        <div style={{ cursor: "pointer"}}>
          <div className={classes.rowItem}>
            <ViewList
              metadataLoading={metadataLoading}
              views={views}
              width={200}
              />
          </div>
        </div>
      ),
    },
    {
      title: () => <RenderHeader header={"Warzone"} />,
      dataIndex: "link",
      key: "link",
      align: "center",
      render: (_, { link }) => (
        <div style={{ cursor: "pointer"}}>
          <div className={classes.rowItem}>
            <RenderJoin link={link} />{" "}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={classes.mainContainer}>
      <div></div>
      <div className={classes.container}>
        {!loading ? (
          <>
            <div className={classes.head}>
              <div className={classes.title}>
                {incident_status
                  ? incident_status === "CLOSED"
                    ? "Close"
                    : "Open"
                  : "All"}{" "}
                Incidents ({filteredRows.length})
              </div>
            </div>
            <div className={classes.tableContainer}>
              <Table
                pagination={false}
                scroll={{ y: "calc(100vh - 15em)" }}
                columns={columns}
                dataSource={filteredRows}
                onRow={({ key }) => {
                  return {
                    onClick: () => {
                      navigate(`/incidents/${key}/0/overview`, {
                        replace: false,
                      });
                    },
                  };
                }}
              />
            </div>
          </>
        ) : (
          <Spin size="large" />
        )}
      </div>
    </div>
  );
};

export default ListIncidents;
