import axios from "axios";

const apiProvider = axios.create({});

// Add 401 interceptor
apiProvider.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error && error.response && error.response.status === 401) {
      // Dispatch action that user is being force logged out
      // await dispatch(setForceLogout());
      console.error("401, unauthenticated");
    }

    return Promise.reject(error);
  }
);

export default apiProvider;
