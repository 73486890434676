import * as React from "react";
import withAuth from "./hoc/withAuth";
import RouterOutlet from "./routing/RouterOutlet";
import { LOGIN_ROUTE } from "./routing/utils";
import { Navigate } from "react-router";

const App = (props: { authenticated: any }) => {
  const { authenticated } = props;

  if (!authenticated) {
    return <Navigate to={LOGIN_ROUTE} replace />;
  }
  return <RouterOutlet />;
};

export default withAuth(App);
