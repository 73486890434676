import apiProvider from "./provider";
import { getAuthenticationHeaders, getErrorMessage } from "./utils";

interface Response {
  data?: any;
  isSuccess?: boolean;
  errorMessage?: string;
}

export const GET = async (
  url: string,
  params?: any,
  headers: any = getAuthenticationHeaders()
): Promise<Response> => {
  try {
    const response = await apiProvider.get(url, {
      params,
      headers,
    });
    return {
      isSuccess: true,
      data: response?.data?.data,
    };
  } catch (err) {
    if (err?.response?.status === 401) {
      return {
        isSuccess: false,
      };
    }
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const POST = async (
  url: string,
  body: any,
  authorize: boolean = true,
  headers = getAuthenticationHeaders()
): Promise<Response> => {
  try {
    const meta = {
      headers,
    };
    const response = await apiProvider.post(url, body, authorize ? meta : {});
    return {
      isSuccess: true,
      data: response?.data?.data,
    };
  } catch (err) {
    if (authorize && err?.response?.status === 401) {
      return {
        isSuccess: false,
      };
    }
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const PUT = async (
  url: string,
  file: any,
  headers: any = getAuthenticationHeaders()
): Promise<Response> => {
  try {
    await apiProvider.put(url, file, { headers });
    return {
      isSuccess: true,
    };
  } catch (err) {
    if (err?.response?.status === 401) {
      return {
        isSuccess: false,
      };
    }
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
