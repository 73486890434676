import moment from "moment";
import { DataType } from ".";
import { GET, POST } from "../../../../services/apiHandler";
import { getStartTime } from "../../../common/utils";
import { FETCH_GRAPH, FETCH_INTCIDENT, FETCH_METADATA } from "./constants";
import { getLabel } from "./utils";

export const getIncidentsData = async () => {
  try {
    const { isSuccess, errorMessage, data } = await GET(`${FETCH_INTCIDENT}`);
    if (!isSuccess) throw errorMessage;
    let rowData: DataType[] = [];
    let idList: string[] = [];
    let eventIdList: string[] = [];
    const incidents = data.incidents;
    incidents.forEach((incident: any) => {
      idList.push(incident.id);
      eventIdList.push(incident.event.id);
      const start = moment.unix(incident?.event?.startTime / 1000);
      const end = incident?.event?.endTime
        ? moment.unix(incident?.event?.endTime / 1000)
        : moment.unix(Date.now() / 1000);
      const duration = moment
        .duration(end.diff(start), "milliseconds")
        .format("d[d] h[h] m[m]");
      const row: DataType = {
        key: incident?.id,
        count: incident?.eventCount,
        link: "",
        mttr: incident?.meanTimeToResolution
          ? moment
              .duration(incident?.meanTimeToResolution, "seconds")
              .format("d[d] h[h] m[m]")
          : undefined,
        since: duration,
        status: incident?.event?.status === "CLOSED" ? "CLOSED" : "OPEN",
        incident: {
          id: incident?.id,
          alarm: incident?.opsgenieId,
          message: incident?.event?.description,
          name: incident?.name,
          start: getStartTime(incident?.event?.startTime),
        },
        views: [],
        graph: {},
      };
      rowData = [...rowData, row];
    });
    return { idList, rowData, eventIdList };
  } catch (error) {
    throw error;
  }
};

export const getGraphData = async (
  isDay: boolean,
  idList: string[],
  rowData: DataType[]
) => {
  try {
    const {
      data: graphData,
      isSuccess,
      errorMessage,
    } = await POST(`${FETCH_GRAPH}?type=${isDay ? 0 : 1}`, {
      incidentIdList: idList,
    });
    if (!isSuccess) throw errorMessage;

    graphData?.forEach((graph: any, index: number) => {
      const countData: number[] = [];
      const labelData: string[] = [];
      graph?.data?.forEach((datapoint: any) => {
        countData.push(datapoint.count);
        labelData.push(getLabel(datapoint));
      });
      rowData[index].graph = {
        count: countData,
        label: labelData,
      };
    });
    return { rowData };
  } catch (error) {
    throw error;
  }
};

export const getMetaData = async (
  // isDay: boolean,
  idList: string[],
  rowData: DataType[]
) => {
  try {
    const {
      errorMessage,
      isSuccess,
      data: metadata,
    } = await POST(`${FETCH_METADATA}`, {
      eventIdList: idList,
    });
    if (!isSuccess) throw errorMessage;
    metadata?.forEach((item: any, index: number) => {
      item?.views?.views?.forEach((user: any) => {
        rowData[index].views = [
          ...rowData[index].views,
          {
            email: user.email,
            name: user.name,
            picURL: user.profilePicUrl,
          },
        ];
      });
    });
    return { rowData };
  } catch (error) {
    throw error;
  }
};
