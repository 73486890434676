import { ChartData } from ".";
import { GET } from "../../../../services/apiHandler";
import { getStartTime } from "../../../common/utils";
import { FETCH_NR_DATA } from "./constants";

export const getNRData = async (incident_id?: string, event_id?: string) => {
  try {
    const { isSuccess, errorMessage, data } = await GET(
      `${FETCH_NR_DATA(incident_id || "0", event_id || "0")}`
    );
    if (!isSuccess) throw errorMessage;
    console.log(data);
    const NRdata = data?.data?.data;
    const newEndTime = getStartTime(parseInt(data?.endTime));
    const newStartTime = getStartTime(parseInt(data?.startTime));
    const serviceName = NRdata.serviceName;
    let newSummaryData: ChartData[] = [];
    newSummaryData = [
      {
        title: NRdata.webTransactionTime.name,
        link: NRdata.webTransactionTime.chartUrl || "",
        width: "60%",
      },
      {
        title: NRdata.apdexScore.name,
        link: NRdata.apdexScore.chartUrl || "",
        width: "40%",
      },
      {
        title: NRdata.throughput.name,
        link: NRdata.throughput.chartUrl || "",
        width: "50%",
      },
      {
        title: NRdata.errorRate.name,
        link: NRdata.errorRate.chartUrl || "",
        width: "50%",
      },
    ];
    let newTransactionData: ChartData[] = [];
    newTransactionData = [
      {
        title: NRdata.timeConsumingTransaction.name,
        link: NRdata.timeConsumingTransaction.chartUrl || "",
        width: "40%",
      },
      {
        title: NRdata.timeTakenByTransactions.name,
        link: NRdata.timeTakenByTransactions.chartUrl || "",
        width: "60%",
      },
    ];
    let newTransaction1 = [
      {
        link: NRdata.transaction1.responseTime.chartUrl,
        title: "Response Time",
        width: "100%",
        subtitle: NRdata.transaction1.name,
      },
    ];
    let newTransaction2 = [
      {
        link: NRdata.transaction2.responseTime.chartUrl,
        title: "Response Time",
        width: "100%",
        subtitle: NRdata.transaction2.name,
      },
    ];

    return {
      serviceName,
      newEndTime,
      newStartTime,
      newSummaryData,
      newTransactionData,
      newTransaction1,
      newTransaction2,
    };
  } catch (error) {
    throw error;
  }
};
