import React from "react";
import { Navigate, Route, Routes } from "react-router";
import Sidebar from "../../Sidebar";
import CloudWatch from "./CloudWatch";
import Events from "./Events";
import Freshping from "./FreshPing";
import ListIncidents from "./ListIncidents";
import NewRelic from "./NewRelic";
import Overview from "./Overview";
import PgStat from "./PgStat";
import RDS from "./RDS";
import Reddis from "./Reddis";
import { allIncidentsList, singleIncidentList } from "./utils";

function Incidents() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Sidebar items={allIncidentsList} indent={30} step={30} />}
      >
        <Route path="all" element={<ListIncidents />} />
        <Route path="open" element={<ListIncidents incident_status="OPEN" />} />
        <Route
          path="close"
          element={<ListIncidents incident_status="CLOSED" />}
        />
        <Route path="action" element={<>Action Requests</>} />
        <Route path="/" element={<Navigate replace to="./all" />} />
      </Route>
      <Route
        path="/:incident_id"
        element={<Sidebar items={singleIncidentList} />}
      >
        <Route path=":event_id/overview" element={<Overview />} />
        <Route path=":event_id/pg_stat" element={<PgStat />} />
        <Route path=":event_id/new_relic" element={<NewRelic />} />
        <Route path=":event_id/rds" element={<RDS />} />
        <Route path=":event_id/reddis" element={<Reddis />} />
        <Route path=":event_id/freshping" element={<Freshping />} />
        <Route path=":event_id/cloudwatch" element={<CloudWatch />} />
        <Route path="events" element={<Events />} />
        <Route
          path=""
          element={<Navigate replace={false} to="./0/overview" />}
        />
      </Route>
      <Route path="*" element={<Navigate to="/invalid" />} />
    </Routes>
  );
}

export default Incidents;
