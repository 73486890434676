import moment from "moment";
import { EventData, Note } from ".";
import { GET, POST } from "../../../../services/apiHandler";
import { getDuration, getStartTime } from "../../../common/utils";
import { User as ViewUser } from "../../../ViewList";
import {
  FETCH_EVENT,
  FETCH_EVENTS_VIEWS,
  FETCH_INTCIDENT_SUMMARY,
  FETCH_WARZONE_DATA,
  FETCH_WARZONE_LINK,
} from "./constants";
import { Summary } from "./Playbook";
import { User } from "./Warzone";

export const getEventData = async (incident_id?: string, event_id?: string) => {
  try {
    const { isSuccess, errorMessage, data } = await GET(
      `${FETCH_EVENT(incident_id, event_id)}`
    );

    if (!isSuccess) throw errorMessage;

    const eventData: EventData = {
      description: data.description,
      name: data.name,
      status: data.status === "CLOSED" ? "CLOSED" : "OPEN",
      since: getDuration(data.startTime, data.endTime),
      views: [],
      id: data.id,
      start: getStartTime(data.startTime),
      opsGenieId: data.opsGenieId
    };
    if (data.resolutionNote) {
      eventData.notes = {
        cause: data.resolutionNote?.cause,
        resolution: data.resolutionNote?.resolution,
      };
    }
    return { eventData };
  } catch (error) {
    throw error;
  }
};

export const getEventViews = async (
  incident_id?: string,
  event_id?: string
) => {
  try {
    const { isSuccess, errorMessage, data } = await GET(
      `${FETCH_EVENTS_VIEWS(incident_id, event_id)}`
    );
    if (!isSuccess) throw errorMessage;
    let users: ViewUser[] = [];

    data.forEach((view: any) => {
      users = [
        ...users,
        {
          email: view.email,
          name: view.name,
          picURL: view.profilePicUrl,
        },
      ];
    });

    return { users };
  } catch (error) {
    throw error;
  }
};

export const getIncidentSummary = async (incident_id?: string) => {
  try {
    const { isSuccess, errorMessage, data } = await GET(
      `${FETCH_INTCIDENT_SUMMARY(incident_id)}`
    );
    if (!isSuccess) throw errorMessage;

    let summary: Note | undefined;
    if (data.summary?.summary) {
      summary = {
        cause: data.summary?.summary?.cause,
        resolution: data.summary?.summary?.resolution,
      };
    }
    let allSummaries: Summary[] = [];
    data.recentSummary?.forEach((note: any) => {
      allSummaries = [
        ...allSummaries,
        {
          id: note?.id,
          note: {
            cause: note?.resolutionNote?.cause,
            resolution: note?.resolutionNote?.resolution,
          },
          timestamp: getStartTime(Date.parse(note?.resolutionNote?.updatedAt)),
        },
      ];
    });
    return { summary, allSummaries };
  } catch (error) {
    throw error;
  }
};

export const postIncidentSummary = async (
  cause?: string,
  resolution?: string,
  incident_id?: string
) => {
  try {
    const { isSuccess, errorMessage, data } = await POST(
      `${FETCH_INTCIDENT_SUMMARY(incident_id)}`,
      {
        cause,
        resolution,
      }
    );
    if (!isSuccess) throw errorMessage;

    return { summary: data.summary };
  } catch (error) {
    throw error;
  }
};

export const getWarzoneData = async (
  incident_id?: string,
  event_id?: string
) => {
  try {
    const { isSuccess, errorMessage, data } = await GET(
      `${FETCH_WARZONE_DATA(incident_id, event_id)}`
    );
    if (!isSuccess) throw errorMessage;
    let users: User[] = [];
    data.forEach((user: any, index: number) => {
      users = [
        ...users,
        {
          email: user.email,
          key: index.toString(),
          lastJoined: moment(user.joinedAt).fromNow(),
          name: user.name,
          profilePic: user.profilePicUrl,
          type: "Admin",
        },
      ];
    });
    return { users };
  } catch (error) {
    throw error;
  }
};
export const getWarzoneLink = async (
  incident_id?: string,
  event_id?: string
) => {
  try {
    const { isSuccess, errorMessage, data } = await GET(
      `${FETCH_WARZONE_LINK(incident_id, event_id)}`
    );
    if (!isSuccess) throw errorMessage;

    return { link: data.link };
  } catch (error) {
    throw error;
  }
};
