import React from "react";
import { createUseStyles } from "react-jss";

const RenderStatus = ({ status }: { status: "OPEN" | "CLOSED" }) => {
  const styles = createUseStyles({
    status: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 4,
      borderRadius: 20,
      fontSize: "0.75rem",
      width: "70px",
      margin: "10px auto",
      background:
        status === "OPEN" ? "rgba(245, 34, 45, 0.1)" : "rgba(82, 196, 26, 0.1)",
      color: status === "OPEN" ? "#F5222D" : "#52C41A",
    },
  });

  const classes = styles();
  return (
    <div className={classes.status}>
      {status === "OPEN" ? "Open" : "Closed"}
    </div>
  );
};

export default RenderStatus;
