import React from "react";
import styles from "./styles";

const Reddis = () => {
  const classes = styles();

  return (
    <div className={classes.mainContainer}>
      <div></div>
      <div className={classes.container}>
        <div className={classes.head}>
          <div className={classes.title}>Reddis</div>
        </div>
      </div>
    </div>
  );
};

export default Reddis;
