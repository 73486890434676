import { Spin } from "antd";
import * as React from "react";
import { useNavigate } from "react-router";
import { setUser } from "../../reducer";

const Login = () => {
  const navigate = useNavigate();
  const resetStorage = () => {
    const storage = window.localStorage;
    storage.removeItem("authToken");
    storage.removeItem("email");
    storage.removeItem("userId");
    storage.removeItem("isActive");
    storage.removeItem("name");
    storage.removeItem("profilePicUrl");
    storage.removeItem("role");
    storage.removeItem("slackId");
    storage.removeItem("slackOuthToken");
  };

  React.useEffect(() => {
    setUser({
      authToken: "",
      email: "",
      isActive: "",
      name: "",
      profilePicUrl: "",
      role: "",
      slackId: "",
      slackOuthToken: "",
      userId: "",
    });
    resetStorage();
    navigate("/", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default Login;
