import { ChartData, InsightData, Query } from ".";
import { GET } from "../../../../services/apiHandler";
import { getDateTimeUNIX, getISOtoUNIX } from "../../../common/utils";
import { FETCH_RDS_DATA } from "./constants";

export const getRDSData = async (incident_id?: string, event_id?: string) => {
  try {
    const { isSuccess, errorMessage, data } = await GET(
      `${FETCH_RDS_DATA(incident_id || "0", event_id || "0")}`
    );
    if (!isSuccess) throw errorMessage;

    const newEndTime = getDateTimeUNIX(parseInt(data?.endTime));
    const newStartTime = getDateTimeUNIX(parseInt(data?.startTime));
    const database = data?.data?.data?.database;
    const monitoringData: ChartData[] = Object.entries(
      data?.data?.data?.monitoringData
    ).map((data: any) => {
      let newTimestamps = data[1]?.timestamps?.map((timestamp: string) => {
        return getISOtoUNIX(timestamp);
      });
      return {
        timestamps: newTimestamps,
        values: data[1]?.values,
        title: data[1].id,
        unit: data[1].unit,
      };
    });

    const base = data?.data?.data?.performanceInsights?.base;
    let insights: InsightData = {
      labels: [],
      data: [],
    };
    for (const key in base) {
      if (base[key].timestamps.length > insights.labels.length) {
        insights.labels = ISOtoUNIXArr(base[key].timestamps);
      }
      insights.data = [
        ...insights.data,
        {
          name: base[key].name,
          values: base[key].values,
        },
      ];
    }

    const sql = data?.data?.data?.performanceInsights?.sql;
    let queries: Query[] = [];

    sql.forEach((query: any, index: number) => {
      queries = [
        ...queries,
        {
          key: index,
          labels: query?.cpu?.timestamps,
          values: query?.cpu?.values,
          statement: query.statement,
        },
      ];
    });

    return {
      queries,
      insights,
      database,
      newMonitoringData: monitoringData,
      newStartTime,
      newEndTime,
    };
  } catch (error) {
    throw error;
  }
};

const ISOtoUNIXArr = (arr: string[]) => {
  return arr.map((item) => getISOtoUNIX(item));
};
