import MDEditor from "@uiw/react-md-editor";
import { Empty } from "antd";
import React from "react";
import { Note } from ".";
import { style, Summary } from "./Playbook";

const Card = ({
  summary,
  setNote,
  setVisible,
  handleClick,
}: {
  summary?: Summary;
  setNote: React.Dispatch<React.SetStateAction<Note | undefined>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleClick: any;
}) => {
  const classes = style();
  if (!summary) {
    return (
      <div className={classes.editor} data-color-mode="light">
        <Empty />
      </div>
    );
  }

  // const handleClick = () => {
  //   setNote({
  //     cause: summary.note?.cause,
  //     resolution: summary.note?.resolution,
  //   });
  //   setVisible(true);
  // };

  return (
    <div
      className={classes.editor}
      data-color-mode="light"
      onClick={handleClick}
    >
      {summary.timestamp && (
        <div className={classes.timestamp}>{summary.timestamp}</div>
      )}
      {summary?.note?.cause ? (
        <>
          {" "}
          <div className={classes.editorTitle}>Cause</div>
          <MDEditor.Markdown
            style={{ position: "relative", fontSize: "0.75rem" }}
            source={summary?.note?.cause}
            linkTarget="_blank"
          />{" "}
        </>
      ) : null}
      {summary?.note?.resolution ? (
        <>
          {" "}
          <div className={classes.editorTitle}>Resolution</div>
          <MDEditor.Markdown
            style={{ position: "relative", fontSize: "0.75rem" }}
            source={summary?.note?.resolution}
            linkTarget="_blank"
          />{" "}
        </>
      ) : null}
    </div>
  );
};

export default Card;
