import { Empty, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import RenderChart from "./RenderChart";
import RenderTopTransaction from "./RenderTopTransaction";
import { getNRData } from "./service";
import styles from "./styles";

export interface ChartData {
  title: string;
  subtitle?: string;
  link: string;
  width: string;
}

const NewRelic = () => {
  const [summaryData, setSummaryData] = useState<ChartData[]>([]);
  const [transactionData, setTransactionData] = useState<ChartData[]>([]);
  const [transaction1, setTransaction1] = useState<ChartData[]>([]);
  const [transaction2, setTransaction2] = useState<ChartData[]>([]);
  const [serviceName, setServiceName] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [loading, setLoading] = useState(false);

  const classes = styles();
  const { incident_id, event_id } = useParams();

  useEffect(() => {
    const fetchNRData = async () => {
      try {
        setLoading(true);
        const {
          newSummaryData,
          newTransaction1,
          newTransaction2,
          newTransactionData,
          newEndTime,
          newStartTime,
          serviceName,
        } = await getNRData(incident_id, event_id);
        setSummaryData(newSummaryData);
        setTransactionData(newTransactionData);
        setTransaction1(newTransaction1);
        setTransaction2(newTransaction2);
        setEndTime(newEndTime);
        setStartTime(newStartTime);
        setServiceName(serviceName);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchNRData();
  }, [event_id, incident_id]);

  return (
    <div className={classes.mainContainer}>
      <div></div>
      <div className={classes.container}>
        {!loading ? (
          <>
            {summaryData.length ? (
              <div className={classes.innerCont}>
                <div className={classes.title}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div className={classes.head}>{serviceName}</div>
                    <div>
                      Summary
                      <span
                        style={{
                          fontWeight: 300,
                          fontSize: "1rem",
                          padding: "0 0.5rem",
                        }}
                      >{` (${startTime}  -  ${endTime})`}</span>
                    </div>
                  </div>
                </div>
                <div className={classes.chartCont}>
                  {summaryData.map((summary, index) => {
                    return (
                      <RenderChart
                        key={index}
                        width={summary.width}
                        url={summary.link}
                        title={summary.title}
                      />
                    );
                  })}
                </div>
                <div className={classes.title}>Transactions</div>
                <div className={classes.chartCont}>
                  {transactionData.map((summary, index) => {
                    return (
                      <RenderChart
                        key={index}
                        width={summary.width}
                        url={summary.link}
                        title={summary.title}
                      />
                    );
                  })}
                </div>
                <div className={classes.title}>
                  Most Time Consuming Transactions
                </div>
                <RenderTopTransaction
                  transactionData={transaction1}
                  title={"Top Most Consuming"}
                />
                <RenderTopTransaction
                  transactionData={transaction2}
                  title={"Second Most Consuming"}
                />
              </div>
            ) : (
              <Empty />
            )}
          </>
        ) : (
          <Spin size="large" />
        )}
      </div>
    </div>
  );
};

export default NewRelic;
